import { defineStore } from 'pinia'
import { useCustomerTypeStore } from '~/stores/customerTypeStore'
import { useFormsStore } from '~/stores/forms/formsStore'
import { useRetailerStore } from '~/stores/retailerStore'
import { IStep } from '~/types/steps'
import { ECodeFormType } from '~/types/commCodes'
import { EStoreStatus } from '~/types/forms'

export enum EEnquirySteps {
  Enquiry = 'Enquiry',
  Showroom = 'Showroom',
  ContactForm = 'About you',
  CheckFinanceEligibility = 'Check finance eligibility',
  ApplyForFinance = 'Apply for finance',
  ThankYou = 'Thank you'
}

export const steps: IStep[] = [
  {
    label: EEnquirySteps.Enquiry,
    title: "You're nearly there..."
  },
  {
    label: EEnquirySteps.Showroom,
    title: 'Preferred Showroom'
  },
  {
    label: EEnquirySteps.ContactForm,
    title: 'Tell us about you'
  },
  {
    label: EEnquirySteps.CheckFinanceEligibility,
    title: ''
  },
  {
    label: EEnquirySteps.ApplyForFinance,
    title: ''
  },
  {
    label: EEnquirySteps.ThankYou,
    title: 'We have received your enquiry'
  }
]

interface IEnquiryStoreState {
  status: EStoreStatus
}

export const useEnquiryStore = defineStore('enquiry', {
  hasNavigation: true,

  state: (): IEnquiryStoreState => {
    return {
      status: EStoreStatus.OK
    }
  },
  getters: {
    hasError(state: IEnquiryStoreState) {
      return state.status === EStoreStatus.ERROR
    },

    getSteps(): IStep[] {
      return steps
    }
  },
  actions: {
    reset(): void {
      this.$reset()
    },

    async submit(detailsUrl: string): Promise<any> {
      const { retailer } = useRetailerStore()

      if (!retailer) {
        this.status = EStoreStatus.ERROR
        return
      }

      const response = await this.api.forms.vehicleDetailsEnquiryCallbackSubmit(
        this.getPayload(detailsUrl)
      )

      if (
        response.error ||
        response.data.requestProcessorResponse.statusCode !== 200
      ) {
        this.status = EStoreStatus.ERROR
        return
      }

      return response
    },

    /**
     * Returns the final submission payload.
     * @param {string} detailsUrl
     */
    getPayload(detailsUrl: string): any {
      const { getVehicle, isNewVehicle, isSubmittingAsBusiness } =
        useFormsStore()
      const { isMotability } = useCustomerTypeStore()
      const config = useRuntimeConfig()
      const route = useRoute()

      const isSmart: boolean = getVehicle!.Brand?.Description === 'SMART'
      const codeOpts = {
        isNew: isNewVehicle,
        isSmart,
        isMotability,
        isBusiness: isSubmittingAsBusiness
      }
      const CommunicationCode = getCommsCodes(ECodeFormType.ENQUIRY, codeOpts)

      return {
        ...getFormPayload(route, isSmart, true, detailsUrl),
        ...getValuationInfo(),
        EnquiryInfo: {
          ...getEnquiryInfoCommons(config, isSmart),
          CommunicationCode,
          RequestSource: getRequestSources(ECodeFormType.ENQUIRY, codeOpts),
          TagURL: getTagUrl(CommunicationCode),
          FormID: getFormId(isSmart, isNewVehicle)
        }
      }
    }
  }
})

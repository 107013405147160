<template>
  <div class="form-call-to-action-directory">
    <header class="os-form__header">
      <h2 class="os-form__title">What would you like to do?</h2>
      <p>
        Select one of the following options below and your selected Showroom
        will be in touch to discuss next steps.
      </p>
    </header>
    <FormsCtaDirectoryContent :directory-buttons="directoryButtons" />
  </div>
</template>

<script lang="ts">
import { useFormsStore } from '~/stores/forms/formsStore'

export default {
  name: 'CtaDirectory',
  setup() {
    const { $dataLayer } = useNuxtApp()
    const formsStore = useFormsStore()

    const directoryButtons = directoryOptions(undefined, true)

    onMounted(() => {
      $dataLayer.pageView({
        pageCategory: 'interested',
        category: 'interested',
        vehicle: vehicleAnalytics(formsStore.getVehicle)
      })
    })

    return { directoryButtons }
  }
}
</script>

<template>
  <div class="thankYou">
    <p class="os-form__subtitle">
      The filters you have enquired on are listed below
    </p>

    <TagCard :title="formattedDate" :tags="callbackTags" class="mb-4" />

    <p class="os-form__subtitle os-form__subtitle--borderless mb-8">
      We will be in contact shortly.
    </p>

    <FormsSectionMarketingDeclaration v-if="isEnabled" />
  </div>
</template>

<script lang="ts">
import { useFiltersStore } from '~/stores/filtersStore'
import { useFilterDataStore } from '~/stores/filterDataStore'

export default {
  setup() {
    const filtersStore = useFiltersStore()
    const filterDataStore = useFilterDataStore()
    const { formatDate } = useDate()

    const filterDataNew = computed(() => filterDataStore.getFilterDataNew)
    const filterDataUsed = computed(() => filterDataStore.getFilterDataUsed)

    const formattedDate = computed<string>(() => {
      return formatDate(new Date(), 'DD MMMM YYYY')
    })

    const callbackTags = computed<string[]>(() =>
      tags.fromFilterCollection(filtersStore.active, {
        New: filterDataNew.value,
        Used: filterDataUsed.value
      })
    )

    const { isEnabled } = useFeatureFlag('onedoc_enabled')

    return {
      formattedDate,
      callbackTags,
      isEnabled
    }
  }
}
</script>

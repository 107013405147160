/**
 * Returns the selected filter's full data object from
 * the server, in order to populate the correct data.
 *
 * @param {array} collection
 * @param {number} value
 */
function getFilterData(collection, value) {
  if (Array.isArray(value)) {
    const descriptions = []

    value.forEach((val) => {
      descriptions.push(
        collection.find(({ Id }) => Number(Id) === Number(val))?.Description
      )
    })

    return {
      Description: descriptions.join(', ')
    }
  }

  return collection.find(({ Id }) => Id === value)
}

/**
 * Function that maps the criteria object keys
 * with the proper icon and text for display.
 *
 * @param {string} filterName
 * @param {string | number | object} value
 * @param {object} filterData
 */
function populateFilterData(filterName, value, filterData) {
  if (value === undefined) return ''
  switch (filterName) {
    case 'DeliveryTime':
      return getFilterData(filterData.DeliveryTime, value).Description
    case 'FuelId':
      return getFilterData(filterData.Fuels, value).Description

    case 'ModelId':
      const models = []

      filterData.Brands.forEach((brand) => models.push(...brand.Models))

      return getFilterData(models, value)?.Description

    case 'ColourId':
      return getFilterData(filterData.Colours, value).Description

    case 'TransmissionId':
      return getFilterData(filterData.Transmissions, value).Description

    case 'EngineId':
      return getFilterData(filterData.Engines, value).Description

    case 'UpholsteryId':
      return getFilterData(filterData.Upholsteries, value).Description

    case 'MonthlyPrice':
      return `${getFormattedBudget(value)} (monthly)`

    case 'Price':
    case 'RetailPrice':
    case 'OTR':
      return `${getFormattedBudget(value)} (price)`

    case 'Mileage':
      return `${formatWithCommas(value.Min)} - ${formatWithCommas(
        value.Max
      )} miles`

    case 'Emission':
      return `${value.Min} - ${value.Max} g/km`

    case 'Keywords':
      return value.join(', ')

    case 'Mapped_Line':
    case 'LineId':
      return getFilterData(filterData.Lines, value).Description
    case 'PackageId':
      return getFilterData(filterData.Packages, value).Description

    case 'Age':
      return `${value.Min} - ${value.Max}`

    case 'VehicleType':
      return value === 2 ? 'Used car' : 'New car'

    case 'Radius':
      const location = value.Lat
        ? 'Current location'
        : value.Postcode.toUpperCase()
          ? value.Postcode.toUpperCase()
          : ''

      const distance = value.Distance
        ? value.Distance === 5000
          ? 'National'
          : `${value.Distance} Miles`
        : ''

      return `${location}, ${distance}`

    case 'RetailerName':
      return `${value}`
  }
}

const initialFilters = (defaults = {}) => ({
  ModelId: [],
  DeliveryTime: [],
  ColourId: {
    Values: []
  },
  TransmissionId: {
    Values: []
  },
  FuelId: {
    Values: []
  },
  BodyStyleId: {
    Values: []
  },
  EngineId: [],
  UpholsteryId: {
    Values: []
  },
  Budget: {
    MonthlyPrice: {
      Min: null,
      Max: null
    },
    Price: {
      Min: null,
      Max: null
    }
  },
  Emission: {
    Min: null,
    Max: null
  },
  Age: {
    Min: null,
    Max: null
  },
  Mileage: {
    Min: null,
    Max: null
  },
  LineId: [],
  PackageId: [],
  Radius: {
    Distance: 25,
    Postcode: ''
  },
  Keywords: [],
  RetailerId: [],
  RetailerName: '',
  VehicleType: 1,
  ...defaults
})

/**
 * Returns only the non-default filter criteria but also flattens them,
 * so that multiple values of the same filter are considered as
 * separate entries.
 *
 * @param {object} filters
 * @param {object} filterData
 */
function getProcessedActiveFilters(filters, filterData) {
  const getPopulatedFilterData = (key, value) => ({
    data: {
      filter: key,
      text: populateFilterData(key, value, filterData)
    }
  })

  const {
    Age = { Min: null, Max: null },
    Mileage = { Min: null, Max: null },
    Emission = { Min: null, Max: null }
  } = filterData

  return useReduce(
    filters,
    (result, value, key) => {
      if (isEqual(value, initialFilters({ Age, Mileage, Emission })[key])) {
        return result
      }

      if (isEqual(value, { Min: null, Max: null })) {
        return result
      }

      if (value?.Values !== undefined || Array.isArray(value)) {
        const values = value.Values || value

        return key !== 'ModelId'
          ? result.concat(getPopulatedFilterData(key, values))
          : result.concat(
              values.map((nestedValue) => {
                return getPopulatedFilterData(key, nestedValue)
              })
            )
      }

      return result.concat(getPopulatedFilterData(key, value))
    },
    []
  )
}

/**
 * Function that returns an object with the `key` being the filter name
 * and the `value` being a string with the description(s) for current search
 *
 * @param {Object} activeFilters
 * @param {Object} filterData
 * @returns {Object}
 */

function currentSearchCriteria(activeFilters, filterData) {
  const filterObj = {}
  getProcessedActiveFilters(activeFilters, filterData)
    .filter((tag) => tag.data.filter !== 'RetailerId')
    .forEach((ele) => {
      const { data } = ele
      if (filterObj[data.filter]) {
        filterObj[data.filter].push(data.text)
      } else {
        filterObj[data.filter] = [data.text]
      }
    })

  return filterObj
}

/**
 * Function that returns an object with the `key` being the filter name
 * and the `value` being a string with the description(s) with white spaces remove
 *
 * @param {Object} activeFilters
 * @param {Object} filterData
 * @returns {Object}
 */

export function callbackSearchCriteria(activeFilters, filterData) {
  const filterObj = currentSearchCriteria(activeFilters, filterData)
  for (const key in filterObj) {
    filterObj[key] = filterObj[key].join(',').replace(/\s/g, '')
  }
  return filterObj
}

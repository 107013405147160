<template>
  <NuxtLink
    v-if="isHyperLink"
    rel="canonical"
    :to="homepageRoute"
    aria-label="Logo"
    class="outline-offset-4 rounded"
    @click="analytics"
  >
    <OsLogoAnimated class="w-8 h-8 lg:w-auto lg:m-0" />
  </NuxtLink>
  <OsLogoAnimated v-else class="w-8 h-8 lg:w-auto lg:m-0" />
</template>

<script lang="ts">
import type { RouteLocationNamedRaw } from 'vue-router'
import { ERouteConditions, ERoutes } from '~/types/routes'
import { useFiltersStore } from '~/stores/filtersStore'
import { useCustomerTypeStore } from '~/stores/customerTypeStore'

export default {
  setup() {
    const filtersStore = useFiltersStore()
    const customerTypeStore = useCustomerTypeStore()
    const { $dataLayer } = useNuxtApp()

    const isKioskMode = useState('kioskmode')

    const isHyperLink = computed(
      () => !(!isKioskMode.value && filtersStore.isRetailerMode)
    )

    // const { params } = useRoute()

    const homepageRoute = computed<RouteLocationNamedRaw>(() => {
      const query = isKioskMode.value ? (kioskQueryFromCookie() as any) : {}
      return {
        name: ERoutes.Homepage,
        params: {
          condition: filtersStore.routeCondition
            ? ERouteConditions.New
            : ERouteConditions.Used,
          type: customerTypeStore.getCustomerTypeRouteParam
        },
        query
      }
    })

    const analytics = () => {
      $dataLayer.linkClick({
        category: 'global header',
        action: 'logo'
      })
    }

    return {
      isHyperLink,
      homepageRoute,
      analytics
    }
  }
}
</script>

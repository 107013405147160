<template>
  <div>
    <FormsOcdFinanceResult
      v-if="isCompletedFinanceApplication"
      sub-title="Your finance application result"
      :heading="ocdFinanceResult.heading"
      :result-text="ocdFinanceResult.text"
      :waiting-text="financeWaitingText('financeApplication').waiting"
      class="os-form__section"
    />

    <div
      v-if="!isCompletedFinanceApplication || ocdFinanceResult.heading"
      class="os-form__section"
    >
      <div class="os-form__section">
        <p v-if="!isCompletedFinanceApplication" class="os-form__subtitle">
          Your preferred Showroom will be in touch shortly.
        </p>

        <AccordionMenu single-item borderless :bold="false" class="mb-12">
          <AccordionItem>
            <AccordionItemTitle> Your enquiry details </AccordionItemTitle>
            <AccordionItemContent class="pt-4">
              <div class="os-form__section">
                <div class="os-form__subtitle">Your {{ vehicle.Model }}</div>
                <FormsSectionVehicleSpecs :vehicle="vehicle" />
              </div>
              <div class="os-form__section">
                <OsPanel v-if="vehicle">
                  <VehiclePricing :vehicle="vehicle" />
                </OsPanel>
              </div>

              <div v-if="hasOffers" class="os-form__section">
                <VehicleOffers
                  :finance-offers="financeOffers"
                  :non-monetary-offers="nonMonetaryOffers"
                  :monetary-offers="monetaryOffers"
                />
              </div>

              <div v-if="retailer" class="os-form__section">
                <div class="os-form__subtitle">Your Showroom information</div>
                <RetailerInformation :retailer="retailer" :vehicle="vehicle" />
              </div>
              <div
                v-if="whatHappensNextText.length > 0"
                class="os-form__section"
              >
                <div class="os-form__subtitle">What happens next</div>
                <FormsSectionList :list="whatHappensNextText" />
              </div>
            </AccordionItemContent>
          </AccordionItem>
        </AccordionMenu>

        <FormsSectionMarketingDeclaration
          v-if="!isCompletedFinanceApplication && isEnabled"
        />

        <FormsSectionLoginCard
          v-if="!isLoggedIn"
          class="os-form__section"
          :form-type="EFormType.Enquiry"
        />

        <div v-if="isCompletedFinanceApplication" class="os-form__section">
          <div class="os-form__subtitle">Your finance quote</div>
          <FinanceTable :quote="financeQuote" :vehicle="vehicle" />
        </div>

        <SurveyMobile
          :form-type="formType"
          :vehicle="vehicle"
          :used-in-form="true"
          immediate
        />

        <div v-if="isShowFormButtons" class="os-form__section">
          <div class="os-form__subtitle">Your next steps</div>
          <FormsCtaDirectoryContent
            :directory-buttons="directoryButtons"
            :show-finance="false"
            :tracking-options="{ pageCategory: 'enquiry:thank you' }"
          />
        </div>
      </div>
    </div>

    <OsButtonTray v-if="!isShowFormButtons && !isLoggedIn">
      <OsLoginButton
        data-locator="login"
        :tracking-options="{
          category: `form-tray-${EFormType.Enquiry}`
        }"
        @click="onLogin"
      />
    </OsButtonTray>
  </div>
</template>
<script lang="ts">
import { EFormType } from '~/types/forms'
import { IRetailer } from '~/types/retailer'
import { NewVehicle, UsedVehicle } from '~/types/vehicle'
import { useCustomerTypeStore } from '~/stores/customerTypeStore'
import { useFinanceQuotesStore } from '~/stores/finance/quotesStore'
import { useOnlineCreditDecisionStore } from '~/stores/forms/ocd/onlineCreditDecisionStore'
import { ITier } from '~/types/onlineCreditDecision'
import { ERoutes } from '~/types/routes'
import surveyQuestions from '~/constants/surveyQuestions'
import { whatHappensNextMotability } from '~/constants/forms'

export default {
  props: {
    isCompletedFinanceApplication: {
      type: Boolean,
      required: false,
      default: false
    },
    vehicle: {
      type: Object as PropType<NewVehicle | UsedVehicle>,
      required: true
    },
    retailer: {
      type: Object as PropType<IRetailer>,
      required: false,
      default: () => ({})
    }
  },

  setup(props) {
    const route = useRoute()
    const ocdStore = useOnlineCreditDecisionStore()
    const financeQuotesStore = useFinanceQuotesStore()
    const customerTypeStore = useCustomerTypeStore()
    const { trackPageView } = useFormTracking(EFormType.Enquiry)

    const { onLogin } = useCiamLogin()
    const formType = EFormType.Enquiry

    const ocdFinanceResult = computed(() => ocdStore.financeResult)

    const { isLoggedIn } = useProfile()

    const { hasOffers, monetaryOffers, nonMonetaryOffers, financeOffers } =
      useVehicleOffers(toRef(props, 'vehicle'))

    const whatHappensNextText = computed<string[]>(() =>
      customerTypeStore.isMotability ? whatHappensNextMotability : []
    )

    const financeResult = computed<ITier>(() => ocdStore.financeResult)

    const isShowFormButtons = computed<boolean>(() => {
      if (route.name === ERoutes.Offer) return false

      const isSuccessResult =
        financeResult.value?.heading?.indexOf('Congratulations') > -1
      return isSuccessResult && props.isCompletedFinanceApplication
    })

    const directoryButtons = directoryOptions([
      EFormType.Offer,
      EFormType.StockNotification,
      EFormType.TestDrive,
      EFormType.Enquiry
    ]).map((formOption) => {
      if (formOption.formName === EFormType.Enquiry) {
        return {
          ...formOption,
          title: `${formOption.title} <span>(Completed)</span>`,
          disabled: true
        }
      }

      return formOption
    })

    watch(
      () => financeResult.value?.heading,
      (val) => {
        if (val) {
          trackPageView({
            pageCategory: `enquiry:application results`,
            ocdApplicationResults: val
          })
        }
      }
    )

    const financeQuote = computed(() => financeQuotesStore.currentQuoteDto)

    const { isEnabled } = useFeatureFlag('onedoc_enabled')

    return {
      ocdFinanceResult,
      isLoggedIn,
      EFormType,
      monetaryOffers,
      nonMonetaryOffers,
      financeOffers,
      hasOffers,
      financeQuote,
      whatHappensNextText,
      isShowFormButtons,
      directoryButtons,
      onLogin,
      formType,
      surveyQuestions,
      isEnabled
    }
  }
}
</script>

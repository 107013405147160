<template>
  <LayoutSidepanel v-model="sidepanelModel" :name="ESidepanel.FORMS">
    <!--CTA Directory-->
    <FormsCtaDirectory v-if="isCTADirectory" />

    <!--Forms-->
    <component :is="currentFormComponent" v-else-if="currentFormComponent" />

    <div v-else class="text-center">
      <OsIcon name="Loader" class="text-blue-600" />
    </div>

    <!--Navigation-->
    <template #navigation>
      <OsButton
        :theme="EButtonThemes.back"
        :has-animation="false"
        aria-label="Back"
        :class="{
          'back-button__placeholder': isLastStep || isBackManuallyDisabled
        }"
        @click.prevent="onGoBack"
      >
        <OsIcon name="Arrow" class="rotate-90" />
        Back
      </OsButton>
      <div v-if="!isVariationActive" class="new-breadcrumbs">
        {{ newBreadcrumbText }}
      </div>
    </template>
  </LayoutSidepanel>
</template>

<script lang="ts">
import { EFormType } from '~/types/forms'

import {
  FormsEnquiry,
  FormsValueMyCar,
  FormsCallback,
  FormsTestDrive,
  FormsFinanceEligibility
} from '#components'

import { useFormsStore } from '~/stores/forms/formsStore'
import { IFormNavigation } from '~/types/forms/formNavigation'
import { EButtonThemes } from '~/components/base/Button.vue'
import { useEnquiryStore } from '~/stores/forms/enquiryStore'
import { useOfferFormStore } from '~/stores/forms/offerFormStore'
import { useValueMyCarStore } from '~/stores/forms/valueMyCarStore'
import { useCallbackStore } from '~/stores/forms/callbackStore'
import { useFinanceEligibilityStore } from '~/stores/forms/financeEligibilityStore'
import { useTestDriveStore } from '~/stores/forms/testDriveStore'

const formsStores = {
  [EFormType.Enquiry]: useEnquiryStore,
  [EFormType.Offer]: useOfferFormStore,
  [EFormType.ValueMyCar]: useValueMyCarStore,
  [EFormType.Callback]: useCallbackStore,
  [EFormType.FinanceEligibility]: useFinanceEligibilityStore,
  [EFormType.TestDrive]: useTestDriveStore
}

export default {
  name: 'FormsContainer',
  setup() {
    const { $dataLayer } = useNuxtApp()
    const { currentForm, isBackDisabled, isPreviousStepLogin, backFn } =
      useFormContainer()

    const formsStore = useFormsStore()
    const { sidepanelModel, hideSidepanel, scrollSidepanelToTop } =
      useSidepanel(ESidepanel.FORMS)

    // CTA Directory
    const isCTAFirstStep = ref<boolean>(false)
    const isCTADirectory = computed<boolean>(
      () => currentForm.value === EFormType.CallToActionDirectory
    )

    watch(isCTADirectory, (val) => {
      if (val) {
        isCTAFirstStep.value = true
      }
    })
    watch(sidepanelModel, (val) => {
      if (!val) {
        isCTAFirstStep.value = false
      }
    })

    const currentFormComponent = computed(() => {
      const components: Partial<Record<EFormType, any>> = {
        [EFormType.ValueMyCar]: FormsValueMyCar,
        [EFormType.Enquiry]: FormsEnquiry,
        [EFormType.TestDrive]: FormsTestDrive,
        [EFormType.Callback]: FormsCallback,
        [EFormType.FinanceEligibility]: FormsFinanceEligibility
      }

      return currentForm.value ? components[currentForm.value] : null
    })

    const activeFormStore = computed(() =>
      currentForm.value && formsStores[currentForm.value]
        ? formsStores[currentForm.value]()
        : null
    )

    const navigation = computed<IFormNavigation | undefined>(
      () => activeFormStore.value?.navigation
    )

    const hasPreviousStep = computed(
      () => navigation.value?.previousStep.value || isCTAFirstStep.value
    )

    const newBreadcrumbText = computed<string>(() => {
      const { newBreadcrumbText } = useFormHeader(navigation.value)

      return newBreadcrumbText.value
    })

    const isLastStep = computed<boolean>(
      () => !!navigation.value?.isLastStep.value
    )

    watch(
      () => navigation.value?.currentStepIndex.value,
      () => {
        scrollSidepanelToTop()
      }
    )

    watch(
      () => isLastStep,
      (val) => {
        if (val) {
          formsStore.markFormComplete(currentForm.value!)
        }
      }
    )

    const isBackManuallyDisabled = computed(
      () => isBackDisabled.value && typeof backFn.value !== 'function'
    )

    const isBackEnabled = computed<boolean>(
      () =>
        !isLastStep.value &&
        !isBackManuallyDisabled.value &&
        !activeFormStore.value?.hasError &&
        !!navigation.value?.isBackEnabled.value
    )

    const closeSidepanel = () => {
      hideSidepanel()

      $dataLayer.linkClick({
        action: 'back',
        category: 'interested'
      })
    }

    const { isLoggedIn } = useProfile()

    const onGoBack = () => {
      if (isLastStep.value || isBackManuallyDisabled.value) return

      if (isCTADirectory.value) {
        closeSidepanel()
        return
      }

      if (typeof backFn.value === 'function') {
        backFn.value()
        return
      }

      if (
        (isPreviousStepLogin.value && isLoggedIn.value) ||
        !isBackEnabled.value
      ) {
        if (isCTAFirstStep.value) {
          formsStore.updateForm(EFormType.CallToActionDirectory)
        } else {
          closeSidepanel()
        }

        return
      }

      navigation.value?.back()
    }

    /**
     * OS-4535 Form page numbers A/B test
     * Variations
     * Original :   ID: 6177643310088192
     * Variation #1   ID: 4614111737151488
     */
    const experiment = useExperiment('6051242154131456')

    const isVariationActive = computed(() => {
      return experiment.value?.variationId === '4614111737151488'
    })

    return {
      isVariationActive,
      EButtonThemes,
      sidepanelModel,
      ESidepanel,
      isCTADirectory,
      currentFormComponent,
      closeSidepanel,
      isCTAFirstStep,
      isBackManuallyDisabled,
      backFn,
      onGoBack,
      newBreadcrumbText,
      isBackDisabled,
      isLastStep,

      hasPreviousStep,
      isPreviousStepLogin,
      navigation
    }
  }
}
</script>

<style lang="scss" scoped>
.back-button__placeholder {
  visibility: hidden;
}
.new-breadcrumbs {
  font-size: rem(16);
  color: #606060;
}
</style>

<template>
  <div>
    <InputText
      :model-value="fieldValue"
      :name="field.Identifier"
      :validate="isValidateField(field)"
      :is-error-state="!!fieldErrors.length"
      :placeholder="fieldLabel"
      :rules="isRequired ? 'required' : ''"
      :type="fieldType"
      :is-ocd="true"
      @update:model-value="updateFormValue({ field, value: $event })"
    />
    <OsError v-if="fieldErrors.length">
      {{ fieldErrors[0] }}
    </OsError>
  </div>
</template>

<script lang="ts">
import { IOcdField } from '~/types/forms'

export default {
  name: 'OcdFormFactoryTextInput',
  props: {
    field: {
      type: Object as PropType<IOcdField>,
      required: true
    }
  },
  setup(props) {
    const { updateFormValue, getFieldValue, isValidateField, getFieldErrors } =
      useFormFactory()

    const fieldValue = computed(() => getFieldValue(props.field))
    const isDataSaved = computed(() => props.field.Value.length > 0)
    const fieldErrors = computed(() => getFieldErrors(props.field))
    const fieldLabel = computed(() => `${props.field.Label}`)
    const fieldType = computed(
      () => props.field.Type && props.field.Type.toLowerCase()
    )

    const isRequired = computed(() => props.field.IsRequired)

    return {
      fieldValue,
      isDataSaved,
      fieldErrors,
      fieldLabel,
      fieldType,
      isValidateField,
      updateFormValue,
      isRequired
    }
  }
}
</script>

<template>
  <div class="flex flex-col">
    <InputDate
      :model-value="fieldValue"
      :label="fieldLabel"
      :errors="fieldErrors"
      @update:model-value="update($event)"
    />
  </div>
</template>

<script lang="ts">
import { IOcdField } from '~/types/forms'

export default {
  name: 'OcdFormFactoryInputDate',

  props: {
    field: {
      type: Object as PropType<IOcdField>,
      required: true
    }
  },

  setup(props) {
    const { updateFormValue, getFieldValue, getFieldErrors } = useFormFactory()

    const fieldValue = computed(() => getFieldValue(props.field))
    const fieldErrors = computed(() => getFieldErrors(props.field))
    const fieldLabel = computed(
      () =>
        `${props.field.Label}${!props.field.IsRequired ? ' [Optional]' : ''}`
    )

    const fieldType = computed(
      () => props.field.Type && props.field.Type.toLowerCase()
    )

    const update = (value: string) => {
      updateFormValue({ field: props.field, value })
    }

    return {
      update,
      fieldType,
      fieldValue,
      fieldErrors,
      fieldLabel
    }
  }
}
</script>

<template>
  <div>
    <p class="os-form__text-content">
      Based on your registration
      <strong>{{ valueMyCarStore.valuationRegistration }}</strong
      >, we have found a matching car.
    </p>

    <div class="os-form__section">
      <div class="os-form__subtitle">Your car</div>
      <TagCard
        v-if="currentModel"
        :title="currentModel.heading"
        :tags="currentModel.tags"
      />
    </div>

    <div class="os-form__section">
      <div class="os-form__subtitle">Choose the condition of your car</div>
      <InputRadioButton
        v-for="(condition, index) in carConditions"
        :key="condition.conditionAnswer"
        :is-active="isActive(condition.grade)"
        :is-row="true"
        @event="onConditionSelect(index)"
      >
        <h6>{{ condition.condition }}</h6>
        <p>{{ condition.description }}</p>
      </InputRadioButton>
      <div class="os-form__text-content">
        If you choose to finance a car later, the estimated value of your
        current car may affect the amount you wish to borrow and your credit
        decision.
      </div>
    </div>

    <OsButtonTray>
      <OsButton
        :is-primary="true"
        :is-disabled="!canProceed"
        @click.prevent="$emit('next')"
      >
        {{ `Next: ${nextStepTitle}` }}
      </OsButton>
    </OsButtonTray>
  </div>
</template>

<script lang="ts">
import {
  useValueMyCarStore,
  carConditions
} from '~/stores/forms/valueMyCarStore'

export default {
  name: 'Condition',
  props: {
    nextStepTitle: {
      type: String,
      required: true
    }
  },

  emits: ['next'],

  setup() {
    const valueMyCarStore = useValueMyCarStore()

    const currentModel = computed(() => {
      return valueMyCarStore.currentModel
    })

    const canProceed = computed(() => {
      return valueMyCarStore.chosenCondition !== null
    })

    const selectedCondition = computed(() => {
      return valueMyCarStore.chosenCondition
    })

    const isActive = (subjectGrade: number): boolean => {
      return selectedCondition.value?.grade === subjectGrade
    }

    const onConditionSelect = (index: number) => {
      valueMyCarStore.updateCondition(carConditions[index])
    }

    return {
      currentModel,
      canProceed,
      selectedCondition,
      isActive,
      onConditionSelect,
      carConditions,
      valueMyCarStore
    }
  }
}
</script>

<template>
  <div class="testDriveButtons">
    <InputRadioButton
      class="mb-4"
      :is-active="!isRetailerTestDrive"
      :disabled="!hasHomeTestDrive"
      @event="isRetailerTestDrive = false"
    >
      From your home address (within 25 mile radius)
    </InputRadioButton>
    <InputRadioButton
      :is-active="isRetailerTestDrive"
      @event="isRetailerTestDrive = true"
    >
      From the Showroom
    </InputRadioButton>
  </div>
</template>

<script lang="ts">
export default {
  name: 'TestDriveButtons',

  props: {
    hasHomeTestDrive: {
      type: Boolean,
      required: false,
      default: true
    },
    modelValue: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const isRetailerTestDrive = computed({
      get() {
        return props.modelValue
      },
      set(val) {
        emit('update:modelValue', val)
      }
    })

    watch(
      () => props.hasHomeTestDrive,
      (val) => {
        if (!val) isRetailerTestDrive.value = true
      },
      { immediate: true }
    )

    return {
      isRetailerTestDrive
    }
  }
}
</script>

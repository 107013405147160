import { IOcdField } from '~/types/forms'
import { useFormFactoryStore } from '~/stores/forms/ocd/formFactoryStore'

export const useFormFactory = () => {
  const ocdFormFactoryStore = useFormFactoryStore()

  const updatedFieldsById = computed(
    () => ocdFormFactoryStore.updatedFieldsById
  )

  const updateFormValue = (payload: { field: IOcdField; value: string }) => {
    ocdFormFactoryStore.updateFormValue(payload)
  }

  const getFieldErrors = (field: IOcdField): string[] => {
    const fieldValue = updatedFieldsById.value[field.Identifier]

    if (field.Error === null) return []
    if (fieldValue?.value) return [field.Error]

    return fieldValue?.error?.length > 0 ? fieldValue.error : []
  }

  const getFieldValue = (field: IOcdField): string => {
    const fieldValue = updatedFieldsById.value[field.Identifier]
    const hasFieldValue = fieldValue != null
    return hasFieldValue ? fieldValue?.value : field.Value
  }

  const isValidateField = (field: IOcdField): boolean => {
    const fieldValue = updatedFieldsById.value[field.Identifier]

    return field.IsRequired || fieldValue?.value?.length > 0
  }

  return {
    updatedFieldsById,
    updateFormValue,
    getFieldErrors,
    getFieldValue,
    isValidateField
  }
}

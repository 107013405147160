<template>
  <OsCard class="car-specs">
    <ImageCar
      v-if="vehicle"
      :width="400"
      :height="176"
      sizes="400px"
      :vehicle="vehicle"
      :alt="`Image of ${vehicle.Description}`"
    ></ImageCar>
    <OsCardContent>
      <h6 class="mb-2">{{ vehicle.Description }}</h6>
      <div>
        <TagList v-if="specsTags.length" :tags="specsTags" />
      </div>
    </OsCardContent>
  </OsCard>
</template>

<script lang="ts">
import { NewVehicle, UsedVehicle } from '~/types/vehicle'
import { useVehiclesSearchStore } from '~/stores/vehicles/searchStore'

export default {
  name: 'VehicleSpecs',
  props: {
    vehicle: {
      type: Object as PropType<NewVehicle | UsedVehicle>,
      required: true
    }
  },
  setup(props) {
    const vehicleImage = computed(() => {
      return getVehicleImage({
        url: props.vehicle.Media.MainImageUrl,
        isNightMode: useVehiclesSearchStore().isNightMode,
        vehicle: props.vehicle,
        vehicleClass: props.vehicle.VehicleClass,
        vehicleBodyStyle: props.vehicle.BodyStyle,
        isCropped: true,
        width: 400
      })
    })

    const specsTags = computed<string[]>(() => {
      return tags.fromVehicle(props.vehicle)
    })

    return {
      vehicleImage,
      specsTags
    }
  }
}
</script>

<template>
  <div class="financeEligibilityResult">
    <FormsOcdFinanceResult
      sub-title="Your finance eligibility result"
      :heading="creditRating.heading"
      :result-text="creditRating.text"
      :waiting-text="financeWaitingText('creditRating').waiting"
    />
    <OsButtonTray>
      <OsButton
        :is-disabled="isDisabled"
        :is-loading="isLoadingEnquiry"
        :is-primary="!ocdEnabled"
        @click.prevent="submitEnquiry"
      >
        {{ isLoadingEnquiry ? 'Please wait' : 'Send enquiry' }}
      </OsButton>
      <OsButton
        v-if="ocdEnabled && !isSuperRed"
        :is-primary="true"
        :is-disabled="isDisabled"
        :is-loading="isLoadingFinanceApplication"
        @click.prevent="requestFinanceApplication"
      >
        {{
          isLoadingFinanceApplication
            ? 'Please wait'
            : 'Next: Apply for finance'
        }}
      </OsButton>
    </OsButtonTray>
  </div>
</template>

<script lang="ts">
import { useCreditCheckStore } from '~/stores/forms/ocd/creditCheckStore'
import { EFormType } from '~/types/forms'
import { useFormsStore } from '~/stores/forms/formsStore'
import { ICreditRatingTier } from '~/types/onlineCreditDecision'
import { useOnlineCreditDecisionStore } from '~/stores/forms/ocd/onlineCreditDecisionStore'
import { useFinanceApplicationStore } from '~/stores/forms/ocd/financeApplicationStore'

export default {
  name: 'OcdCreditCheckFinanceResult',

  emits: ['next'],

  setup(props, { emit }) {
    const ocdStore = useOnlineCreditDecisionStore()
    const financeApplicationStore = useFinanceApplicationStore()
    const formsStore = useFormsStore()
    const creditCheckStore = useCreditCheckStore()
    const isLoadingEnquiry = ref<boolean>(false)
    const isLoadingFinanceApplication = ref<boolean>(false)
    const { currentStep } = creditCheckStore.navigation
    const { trackPageView } = useFormTracking(
      EFormType.FinanceEligibility,
      currentStep
    )

    const vehicle = computed(() => formsStore.getVehicle)

    const { $dataLayer } = useNuxtApp()

    // OCD Enabled feature flag
    const { isEnabled: ocdEnabled } = useFeatureFlag('ocd_enabled')

    const isDisabled = computed<boolean>(
      () =>
        ocdStore.isLoading ||
        isLoadingEnquiry.value ||
        isLoadingFinanceApplication.value ||
        !creditRating.value?.heading ||
        !creditRating.value?.text
    )

    const isSuperRed = computed(
      () => creditCheckStore.creditRatingTier === ICreditRatingTier.SuperRed
    )

    const creditRating = computed(() => ocdStore.creditRating)

    watch(
      () => creditRating.value?.heading,
      (val) => {
        if (val) {
          trackPageView({
            pageCategory: `ocd:${currentStep.value.label.toLowerCase()}`,
            eligibilityResults: val
          })
        }
      }
    )

    const submitEnquiry = async () => {
      isLoadingEnquiry.value = true
      await ocdStore.sendOcdEnquiry()

      $dataLayer.linkClick({
        pageCategory: `ocd:${currentStep.value.label.toLowerCase()}`,
        category: 'send_enquiry',
        action: '',
        vehicle: vehicleAnalytics(vehicle.value),
        eligibilityResults: creditRating.value.heading
      })

      if (!ocdStore.hasError) emit('next', 1)
    }

    const requestFinanceApplication = async () => {
      isLoadingFinanceApplication.value = true
      await financeApplicationStore.getForm()

      $dataLayer.linkClick({
        pageCategory: `ocd:${currentStep.value.label.toLowerCase()}`,
        category: 'apply_for_finance',
        action: '',
        vehicle: vehicleAnalytics(vehicle.value),
        eligibilityResults: creditRating.value.heading
      })

      if (!financeApplicationStore.hasError) emit('next')
    }

    return {
      financeWaitingText,
      isDisabled,
      submitEnquiry,
      isLoadingEnquiry,
      isLoadingFinanceApplication,
      requestFinanceApplication,
      creditRating,
      isSuperRed,
      ocdEnabled
    }
  }
}
</script>

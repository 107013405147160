<template>
  <DatePicker class="os-date-picker" v-bind="$attrs" />
</template>

<script lang="ts">
import { DatePicker } from 'v-calendar'

export default {
  components: { DatePicker }
}
</script>

<style lang="scss">
@import 'v-calendar/style.css';

.os-date-picker {
  .vc-blue {
    --vc-accent-600: #0078d6;
  }

  .vc-title {
    font-size: 100%;
  }
}
</style>

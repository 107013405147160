<template>
  <FormsOcdFormFactory
    next-step-title="Submit your finance application"
    tracking-form-name="ocd"
    :tracking-page-category-prefix="trackingPageCategoryPrefix"
    @form-factory-finished="submitFinanceApplication"
  >
    <div
      v-if="isAddressStep"
      class="form-factory__disclaimer finance-application mt-12"
      v-html="creditAgencyContent.replace(/(<\/?strong>)(<\/?u>)/g, '')"
    />
  </FormsOcdFormFactory>
</template>

<script lang="ts">
import { useFormsStore } from '~/stores/forms/formsStore'
import { EOcdLabel } from '~/types/forms'
import { useVehicleStore } from '~/stores/vehicles/detailsStore'
import { useOnlineCreditDecisionStore } from '~/stores/forms/ocd/onlineCreditDecisionStore'
import { useFinanceApplicationStore } from '~/stores/forms/ocd/financeApplicationStore'
import { useFormFactoryStore } from '~/stores/forms/ocd/formFactoryStore'

export default {
  inheritAttrs: false,

  emits: ['next'],

  setup(props, { emit }) {
    const ocdStore = useOnlineCreditDecisionStore()
    const ocdFormFactoryStore = useFormFactoryStore()
    const financeApplication = useFinanceApplicationStore()
    const vehicleDetailsStore = useVehicleStore()
    const formsStore = useFormsStore()
    const { $dataLayer } = useNuxtApp()
    const { currentStep } = ocdFormFactoryStore.navigation

    const creditAgencyContent = computed(
      () => ocdStore.content.creditAgencyNotice
    )

    const isAddressStep = computed(
      () =>
        currentStep.value.key === EOcdLabel.SUBMIT_APPLICATION &&
        creditAgencyContent.value
    )

    const submitFinanceApplication = async () => {
      $dataLayer.linkClick({
        pageCategory: `ocd:${currentStep.value.label?.toLowerCase()}`,
        category: `ocd_form`,
        action: 'Submit your finance application'
      })

      // update underOffer status in case the vehicle has been sold while OCD form was being completed
      financeApplication.setIsLoading(true)

      const vehicle = formsStore.getVehicle

      formsStore.updateVehicle({
        UnderOffer: await vehicleDetailsStore.getIsSold(vehicle?.Vin)
      })

      financeApplication.setIsLoading(false)

      emit('next')

      await financeApplication.submitForm()
      await financeApplication.getFinanceResult()
      trackOptimizelyEvent(EOptimizelyEvent.OcdFormComplete)
    }

    const trackingPageCategoryPrefix = computed(() =>
      ocdFormFactoryStore.navigation.currentStepIndex.value === 0
        ? 'stage two '
        : ''
    )

    return {
      isAddressStep,
      creditAgencyContent,
      submitFinanceApplication,
      trackingPageCategoryPrefix
    }
  }
}
</script>

<style lang="scss" scoped>
.form-factory__disclaimer.finance-application {
  &:deep(p:first-of-type) {
    @include sectionHeading();
  }
}
</style>

<template>
  <div class="os-form__section">
    <FormsCtaDirectoryButtons
      v-model="selectedOption"
      :forms="activeForms"
      @option-selected="onOptionSelect"
    />

    <OnlineCreditDecisionNotification
      v-if="showFinance && isNotificationShowing"
      :vehicle="vehicle"
    />
  </div>
</template>

<script lang="ts">
import { useFormsStore } from '~/stores/forms/formsStore'
import { IGoogleDataLayerOptions } from '~/types/googleDataLayer'
import { EFormType, ICallToActionDirectoryOption } from '~/types/forms'

export default {
  name: 'CtaDirectoryContent',

  props: {
    directoryButtons: {
      type: Array as PropType<ICallToActionDirectoryOption[]>,
      required: false,
      default: () => []
    },
    showFinance: {
      type: Boolean,
      required: false,
      default: true
    },
    trackingOptions: {
      type: Object as PropType<Partial<IGoogleDataLayerOptions>>,
      default: null,
      required: false
    }
  },

  setup(props) {
    const { $dataLayer } = useNuxtApp()
    const formsStore = useFormsStore()

    const vehicle = computed(() => formsStore.getVehicle)
    const { showFinance, directoryButtons, activeForms, openSelectedForm } =
      useFormsCta(vehicle)

    const selectedOption = ref<ICallToActionDirectoryOption | null>(null)

    onMounted(() => {
      showFinance.value = props.showFinance
      directoryButtons.value = props.directoryButtons
    })

    const { isNotificationShowing } = useOcd()

    const onOptionSelect = async (option: ICallToActionDirectoryOption) => {
      $dataLayer.linkClick({
        category: 'interested',
        action: option.formName,
        vehicle: vehicleAnalytics(vehicle.value),
        ...props.trackingOptions
      })

      const formName = selectedOption.value?.formName
      if (formName === EFormType.Offer) {
        const scrollY = useState<number>('htmlScrollY', () => 0)
        scrollY.value = 0
        await nextTick()
      }
      if (!(await openSelectedForm(formName))) return

      $dataLayer.linkClick({
        category: 'interested',
        action: `next:${formName}`,
        vehicle: vehicleAnalytics(vehicle.value),
        ...props.trackingOptions
      })
    }

    return {
      vehicle,
      activeForms,
      onOptionSelect,
      selectedOption,
      isNotificationShowing
    }
  }
}
</script>

import { defineStore } from 'pinia'
import { useCustomerTypeStore } from '../customerTypeStore'
import { useFormsStore } from '~/stores/forms/formsStore'
import { IStep } from '~/types/steps'
import { ECodeFormType } from '~/types/commCodes'
import { EStoreStatus } from '~/types/forms'

export enum ETestDriveSteps {
  RequestTestDrive = 'Request a test drive',
  Location = 'Location',
  PickDate = 'Pick a date',
  ContactForm = 'About you',
  ThankYou = 'Thank you'
}

export const steps: IStep[] = [
  {
    label: ETestDriveSteps.RequestTestDrive,
    title: 'Request a test drive'
  },
  {
    label: ETestDriveSteps.Location,
    title: 'Where would you like to take your Test Drive?'
  },
  {
    label: ETestDriveSteps.PickDate,
    title: 'Preferred date and time'
  },
  {
    label: ETestDriveSteps.ContactForm,
    title: 'Tell us about you'
  },
  {
    label: ETestDriveSteps.ThankYou,
    title: 'We have received your request'
  }
]

interface ITestDriveStoreState {
  status: EStoreStatus
  isRetailerTestDrive: boolean
  isAfternoon: boolean
  date: Date | null
}

export const useTestDriveStore = defineStore('testDrive', {
  hasNavigation: true,

  state: (): ITestDriveStoreState => {
    return {
      status: EStoreStatus.OK,
      isRetailerTestDrive: true,
      isAfternoon: false,
      date: null
    }
  },
  getters: {
    hasError(state?: ITestDriveStoreState): boolean {
      return state!.status === EStoreStatus.ERROR
    },
    getSteps(): IStep[] {
      return steps
    }
  },
  actions: {
    async submit(detailsUrl: string): Promise<any> {
      const response = await this.api.forms.vehicleDetailsEnquiryCallbackSubmit(
        this.getPayload(detailsUrl)
      )

      if (response.data?.requestProcessorResponse?.statusCode !== 200) {
        this.status = EStoreStatus.ERROR
        return
      }

      return response
    },
    /**
     * Returns the final submission payload.
     */
    getPayload(detailsUrl: string): any {
      const route = useRoute()
      const config = useRuntimeConfig()
      const {
        getVehicle,
        isNewVehicle,
        updateCustomer,
        isSubmittingAsBusiness
      } = useFormsStore()
      const { isMotability } = useCustomerTypeStore()

      const isSmart: boolean = getVehicle!.Brand?.Description === 'SMART'
      const codeOpts = {
        isNew: isNewVehicle,
        isSmart,
        isMotability,
        isBusiness: isSubmittingAsBusiness
      }
      const CommunicationCode = getCommsCodes(
        ECodeFormType.TEST_DRIVE,
        codeOpts
      )

      if (this.isRetailerTestDrive) {
        updateCustomer({ postcode: undefined })
      }

      return {
        ...getFormPayload(route, isSmart, true, detailsUrl),
        ...getValuationInfo(),
        EnquiryInfo: {
          ...getEnquiryInfoCommons(config, isSmart),
          CommunicationCode,
          RequestSource: getRequestSources(ECodeFormType.TEST_DRIVE, codeOpts),
          TagURL: getTagUrl(CommunicationCode),
          FormID: getFormId(isSmart, isNewVehicle)
        },
        TestDrive: {
          TestDriveType: this.isRetailerTestDrive ? 'RETAILER' : 'HOME',
          Date: getConvertedDate(this.date!),
          Time: this.isAfternoon ? 'AFTERNOON' : 'MORNING'
        }
      }
    },
    reset(): void {
      this.$reset()
    }
  }
})

<template>
  <div v-if="messengerStore.isOnline" class="liveChat">
    <h5 class="liveChat__subTitle">Interested in this car?</h5>
    <p class="liveChat__text">
      {{ liveChatText[isSmart ? 'smart' : 'mercedesBenz'] }}
    </p>
    <OsChatButton :colour="osColours.blue" :ga-category="'results'">
      Live Chat
    </OsChatButton>
  </div>
  <div v-else>
    <h5 class="liveChat__subTitle">Interested in this car?</h5>
    <p class="liveChat__text">
      {{ liveChatText[isSmart ? 'smartOffline' : 'mercedesBenzOffline'] }}
    </p>
  </div>
</template>
<script lang="ts">
import { IVehicle } from '~/types/vehicle'
import { useMessengerStore } from '~/stores/messengerStore'
import { liveChatMessages } from '~/constants/forms'

export default {
  name: 'LiveChat',
  props: {
    vehicle: {
      type: Object as PropType<IVehicle>,
      required: false,
      default: undefined
    }
  },
  setup(props) {
    const { osColours } = useUi()
    const messengerStore = useMessengerStore()

    const isSmart = computed<boolean>(() => {
      return props.vehicle?.Brand?.Description === 'SMART'
    })

    return {
      messengerStore,
      liveChatText: liveChatMessages,
      isSmart,
      osColours
    }
  }
}
</script>
<style lang="scss">
.liveChat {
  width: 100%;

  .chatButton {
    display: flex;
    justify-content: center;
    padding: 0 rem(16);
    height: 40px;
    white-space: nowrap;
    border-radius: 4px;
    border: solid 1px $grey--lightest;
    background-color: $grey--lightest;
    font-size: rem(18);
    letter-spacing: -0.2px;
    text-align: center;
    color: $blue--dark;
    font-family: $mb-font-text;
    cursor: pointer;
    align-items: center;
    width: 100%;

    @include viewport('lg') {
      min-width: 148px;
    }
  }
}

.liveChat__subTitle {
  @include sectionHeading();
}

.liveChat__text {
  font-family: $mb-font-text;
  font-size: rem(14);
  color: $grey--darkest;
  margin-bottom: rem(16);
}
</style>

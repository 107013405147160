<template>
  <aside>
    <button @click="showVMC">
      <NuxtImg
        src="/images/value-my-car.jpg"
        alt="Value My Car"
        width="296"
        height="147"
        class="rounded-lg"
      />
    </button>
    <p class="text-gray-200 text-sm mt-2">
      Find out what your current car is worth.
    </p>
    <button
      class="text-gray-200 font-bold text-sm hover:text-gray-300 flex items-center gap-1"
      @click="showVMC"
    >
      Value My Car
      <OsIcon name="ArrowUp" class="w-3 h-3 rotate-90" />
    </button>
  </aside>
</template>

<script lang="ts" setup>
import { EFormType } from '~/types/forms'
import { useFormsStore } from '~/stores/forms/formsStore'

const props = defineProps<{
  condition: any
}>()

const { $dataLayer, $bus } = useNuxtApp()
const formsStore = useFormsStore()

const { showSidepanel: showFormsSidepanel } = useSidepanel(ESidepanel.FORMS)

const showVMC = () => {
  $bus.emit('mega-menu:close')

  showFormsSidepanel()

  formsStore.updateForm(EFormType.ValueMyCar)

  $dataLayer.linkClick({
    category:
      props.condition === 'new'
        ? 'global_header_find_new_cars'
        : 'global_header_find_approved_used_cars',
    action: 'value my car'
  })
}
</script>

<template>
  <div class="finance-eligibility-privacy">
    <p class="os-form__subtitle">Your privacy</p>
    <div class="os-form__disclaimer" v-html="consentPolicy.Overview"></div>
    <InputCheckbox
      v-model="isChecked"
      small-label
      label="Please tick here to confirm you have read and understood the above."
    />
    <OsButtonTray>
      <OsButton
        :is-loading="isLoading"
        is-primary
        :is-disabled="isLoading || !isChecked"
        @click.prevent="next"
      >
        {{ isLoading ? 'Please wait' : 'Next: Personal details' }}
      </OsButton>
    </OsButtonTray>
  </div>
</template>

<script lang="ts">
import { useCreditCheckStore } from '~/stores/forms/ocd/creditCheckStore'
import { useOnlineCreditDecisionStore } from '~/stores/forms/ocd/onlineCreditDecisionStore'

export default {
  name: 'OcdCreditCheckFinancePrivacy',

  emits: ['next'],

  setup(props, { emit }) {
    const isChecked = ref<boolean>(false)
    const ocdStore = useOnlineCreditDecisionStore()
    const creditCheckStore = useCreditCheckStore()

    const isLoading = computed<boolean>(
      () => creditCheckStore.isCreditCheckLoading
    )
    const consentPolicy = computed(() => ocdStore.consentPolicy)

    const select = () => (isChecked.value = !isChecked.value)

    const next = async () => {
      await creditCheckStore.getForm()
      emit('next')
    }

    return {
      isChecked,
      isLoading,
      consentPolicy,
      select,
      next
    }
  }
}
</script>

<style lang="scss" scoped>
@import './assets/styles/tools/_forms.scss';
.finance-eligibility-privacy {
  .os-form__disclaimer:deep(a) {
    @extend .os-form__link !optional;
  }
  .os-form__disclaimer:deep(p) {
    margin-bottom: rem(16);
    font-size: inherit;
  }
}
</style>

<template>
  <div class="os-footer__wrap">
    <div id="os-footer-footnotes"></div>
    <footer id="os-footer" class="os-footer">
      <div class="row os-footer__subscribe">
        <div class="col-auto">
          <span class="text-lg bold text--white"
            >Sign up for the Mercedes-Benz experience</span
          >
          <div class="text-gray-400 text-base">
            Receive news, offers and exclusive opportunities.
          </div>
        </div>
        <div class="spacer"></div>
        <div class="col-auto os-footer__subscribe-button-col">
          <OsButton
            class="os-footer__link os-footer__link--blue"
            rel="noopener"
            is-primary
            aria-label="Subscribe"
            @click="onSubscribe"
            >Subscribe
          </OsButton>
        </div>
      </div>

      <div class="row no-gutters os-footer__divider">
        <div class="col"></div>
      </div>
      <div class="row">
        <div class="col os-footer__social">
          <div class="row">
            <div
              v-for="(link, name) in socialLinks"
              :key="name"
              class="col-auto"
            >
              <a
                class="os-footer__social-icon"
                target="_blank"
                rel="noopener"
                :href="link.url"
                @click="trackClick(name)"
              >
                <img
                  :src="`/images/icons/${name}.svg`"
                  :width="link.width"
                  :height="link.height"
                  :alt="`${name} logo`"
                />
              </a>
            </div>
          </div>
        </div>

        <div class="col-auto">
          <nav class="os-footer__nav">
            <NuxtLink
              class="os-footer__link"
              :to="getPageRoute(ERoutePageSlugs.MBC)"
              target="_blank"
            >
              © 2004 - {{ new Date().getFullYear() }} Mercedes-Benz UK Ltd </NuxtLink
            >&nbsp;
            <NuxtLink
              class="os-footer__link"
              :to="getPageRoute(ERoutePageSlugs.MSA)"
              target="_blank"
            >
              Modern Slavery Act Statement </NuxtLink
            >&nbsp;
            <NuxtLink
              class="os-footer__link"
              :to="getPageRoute(ERoutePageSlugs.PrivacyPolicy)"
              target="_blank"
            >
              Privacy Policy </NuxtLink
            >&nbsp;
            <NuxtLink
              class="os-footer__link"
              :to="getPageRoute(ERoutePageSlugs.LegalNotice)"
              target="_blank"
            >
              Legal Notice </NuxtLink
            >&nbsp;
            <NuxtLink
              class="os-footer__link"
              to="/page/cookie-policy"
              target="_self"
            >
              Cookie Policy
            </NuxtLink>
          </nav>
        </div>
      </div>
    </footer>
  </div>
</template>

<script lang="ts">
import { ERoutes, ERoutePageSlugs, PageRoute } from '~/types/routes'

export default {
  setup() {
    const { $dataLayer } = useNuxtApp()

    const socialLinks = {
      facebook: {
        url: 'https://www.facebook.com/MercedesBenzUK/',
        width: 16,
        height: 13
      },
      youtube: {
        url: 'https://www.youtube.com/user/mercedesbenzuk',
        width: 32,
        height: 35
      },

      twitter: {
        url: 'https://twitter.com/mercedesbenzuk',
        width: 32,
        height: 35
      },
      instagram: {
        url: 'https://www.instagram.com/mercedesbenzuk/',
        width: 32,
        height: 35
      }
    }

    const getPageRoute = (slug: ERoutePageSlugs): PageRoute => {
      return {
        name: ERoutes.Page,
        params: {
          slug
        }
      }
    }

    const onSubscribe = () => {
      window.open(
        'https://submit.mercedes-benz.co.uk/registermyinterest',
        '_blank'
      )
      trackClick('registermyinterest')
    }

    const trackClick = (action: string) => {
      $dataLayer.linkClick({
        category: 'global footer',
        action
      })
    }
    return {
      socialLinks,
      ERoutes,
      ERoutePageSlugs,
      getPageRoute,
      onSubscribe,
      trackClick
    }
  }
}
</script>

<style lang="scss" scoped>
.os-footer__wrap {
  background: $white;
}

.os-footer {
  background: $black--darkest;
  color: white;
  padding: rem(40) rem(40) rem(24);

  @include viewport('sm', 'max-width') {
    padding: rem(24) rem(16);
  }

  .row {
    &.os-footer__divider {
      margin-top: rem(40);
      padding-bottom: rem(24);
      border-top: 1px solid $grey--dark;

      @include viewport('sm', 'max-width') {
        margin-top: rem(24);
      }
    }

    .os-footer__subscribe-button-col {
      margin-left: auto;
    }

    .os-footer__social {
      .row {
        flex-wrap: nowrap;
      }
      .col-auto {
        margin-right: rem(24);
      }
      img {
        height: 16px;

        @include viewport('sm', 'max-width') {
          height: 24px;
        }
      }
      a:focus {
        outline-offset: 4px;
        border-radius: 4px;
      }
    }

    .os-footer__nav {
      a.os-footer__link {
        color: $grey--dark;
        font-family: $mb-font-text;
        font-size: rem(14);
        text-decoration: none;
        white-space: nowrap;
        margin-left: rem(4);

        &:not(:last-child) {
          padding-right: rem(4);
          border-right: 1px solid $grey--dark;
        }
        &:focus {
          outline-offset: 2px;
          border-radius: 4px;
        }
      }
    }
  }
}
</style>

<template>
  <div class="test-drive-location">
    <FormsSectionInputAutocomplete
      v-if="isMBCUKRetailer && !isFixed"
      v-bind="{ retailer, isMBCUKRetailer }"
    />
    <div v-if="!isMBCUKRetailer || (isMBCUKRetailer && hasRetailer)">
      <div class="os-form__section">
        <FormsTestDriveButtons
          v-model="isRetailerTestDrive"
          v-bind="{ hasHomeTestDrive }"
        />
      </div>
      <div class="os-form__section">
        <div class="os-form__subtitle">
          {{ isRetailerTestDrive ? 'Showroom address' : 'Your address' }}
        </div>
        <transition name="slideDown">
          <RetailerInformation
            v-if="retailer && isRetailerTestDrive"
            :retailer="retailer"
            :vehicle="vehicle"
            :is-smart="isSmart"
            :show-cloudfront-message="true"
            @go-to-results="goToResultsTracking"
          />
          <InputAddress v-else @updated="updateCustomer" />
        </transition>
      </div>
    </div>
    <OsButtonTray>
      <OsButton
        :is-loading="isLoading"
        :is-primary="true"
        :is-disabled="isDisabled || invalid"
        @click.prevent="next"
      >
        {{ isLoading ? 'Please wait' : `Next: ${nextStepTitle}` }}
      </OsButton>
    </OsButtonTray>
  </div>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia'
import { NewVehicle, UsedVehicle } from '~/types/vehicle'
import { EFormType, ICustomer } from '~/types/forms'
import { useTestDriveStore } from '~/stores/forms/testDriveStore'
import { useFormsStore } from '~/stores/forms/formsStore'
import { useRetailerStore } from '~/stores/retailerStore'

export default {
  name: 'TestDriveLocation',

  props: {
    isNew: {
      type: Boolean,
      required: false,
      default: false
    },
    nextStepTitle: {
      type: String,
      required: false,
      default: ''
    },
    isMBCUKRetailer: {
      type: Boolean,
      required: false,
      default: true
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    invalid: {
      type: Boolean,
      required: false,
      default: false
    },
    vehicle: {
      type: Object as PropType<NewVehicle | UsedVehicle>,
      required: false,
      default: () => ({})
    },
    isSmart: {
      type: Boolean,
      required: false,
      default: true
    },
    formType: {
      type: String as PropType<EFormType>,
      required: true
    }
  },

  emits: ['next'],

  setup(props, { emit }) {
    const testDriveStore = useTestDriveStore()
    const formsStore = useFormsStore()
    const { $dataLayer } = useNuxtApp()

    const isRetailerTestDrive = computed({
      get() {
        return testDriveStore.isRetailerTestDrive
      },
      set(val: boolean) {
        testDriveStore.isRetailerTestDrive = val
      }
    })

    const retailerStore = useRetailerStore()
    const { retailer } = storeToRefs(retailerStore)

    const hasRetailer = computed<boolean>(
      () => props.isNew && !isEmpty(retailer.value)
    )

    const isFixed = computed<boolean>(
      () => props.isNew && retailerStore.hasFixedRetailer
    )

    const hasHomeTestDrive = computed<boolean>(() => {
      const condition = props.isNew ? 'New' : 'Used'

      return (
        !!retailer.value?.Services &&
        retailer.value?.Services[condition].length > 0 &&
        retailer.value?.Services[condition].some(
          (el) => el.Description === 'Home test drive'
        )
      )
    })

    const updateCustomer = (form: Partial<ICustomer>) => {
      formsStore.updateCustomer(form)
    }

    const isDisabled = computed<boolean>(
      () =>
        props.isLoading ||
        (!isRetailerTestDrive.value && !formsStore.getCustomer.postcode)
    )

    const goToResultsTracking = (trackingString: string) => {
      $dataLayer.linkClick({
        pageCategory: `${props.formType}:preferred Showroom`,
        action: `${trackingString.toLowerCase()}`
      })
    }

    const next = () => {
      emit('next')
    }

    return {
      isRetailerTestDrive,
      hasRetailer,
      retailer,
      isFixed,
      hasHomeTestDrive,
      isDisabled,
      next,
      updateCustomer,
      goToResultsTracking
    }
  }
}
</script>

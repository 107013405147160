<template>
  <div class="finance-eligibility-benefits os-form__section">
    <h6 class="os-form__subtitle">Are you eligible for finance?</h6>
    <p class="text-gray-400">{{ checkFinanceEligibility.enquiry.details }}</p>
    <FormsSectionList :list="checkFinanceEligibility.enquiry.list" />
    <p class="text-gray-400">
      {{
        checkFinanceEligibility.enquiry.citation[
          ocdEnabled ? 'enabled' : 'disabled'
        ]
      }}
    </p>
  </div>
</template>

<script lang="ts">
export default {
  name: 'FinanceEligibilityBenefits',
  setup() {
    // OCD Enabled feature flag
    const { isEnabled: ocdEnabled } = useFeatureFlag('ocd_enabled')
    return {
      ocdEnabled
    }
  }
}
</script>

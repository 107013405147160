<template>
  <div class="retailer-group os-form__section">
    <slot name="top" />

    <div class="os-form__section">
      <div class="os-form__subtitle">Your preferred Showroom</div>
      <OsSkeletonLoader
        v-if="isFetchingRetailer || !retailerSelectedDropdown"
        width="100%"
        height="363px"
        class="retailer-group__loading"
      />
      <RetailerInformation
        v-else-if="retailerSelected"
        :retailer="retailerSelected"
        :vehicle="vehicle"
      />
      <div>
        <OsSkeletonLoader
          v-if="isFetchingRetailer"
          width="100%"
          height="54px"
        />
        <InputDropdown
          v-else
          :key="`retailerGroupDropdown${retailerSelectedDropdown.id}`"
          :model-value="retailerSelectedDropdown"
          :items="dropdownItems"
          expand-upwards
        />
      </div>
    </div>

    <div>
      <OsButtonTray>
        <OsButton
          :is-loading="isLoading"
          :is-primary="true"
          :is-disabled="isDisabled"
          @click.prevent="next"
        >
          {{ isLoading ? 'Please wait' : `Next: ${nextStepTitle}` }}
        </OsButton>
      </OsButtonTray>
    </div>
  </div>
</template>

<script lang="ts">
import { useRetailerStore } from '~/stores/retailerStore'
import { IRetailer } from '~/types/retailer'
import { NewVehicle, UsedVehicle } from '~/types/vehicle'
import { IDropdownItem } from '~/types/dropdown'

interface IRetailerGroupDropdown extends IDropdownItem {
  gssnId?: string
}

// TODO: Refactor this component
export default {
  name: 'RetailerGroup',
  props: {
    formType: {
      type: String,
      required: false,
      default: ''
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    vehicle: {
      type: Object as PropType<NewVehicle | UsedVehicle>,
      required: false,
      default: undefined
    },
    nextStepTitle: {
      type: String,
      required: false,
      default: ''
    }
  },

  emits: ['next'],

  setup(props, { emit }) {
    const { $api } = useNuxtApp()

    const isFetchingRetailer = ref<boolean>(false)
    const retailerItems = ref<IRetailer[]>([])

    const retailerStore = useRetailerStore()

    const retailerSelected = computed(() => retailerStore.retailer)
    const retailerSelectedDropdown = computed<IDropdownItem>(() => {
      const isSmartDescription =
        isSmart.value && retailerSelected.value?.smartDescription
      return retailerSelected.value
        ? {
            id: retailerSelected.value.Id || '',
            text:
              retailerSelected.value[
                isSmartDescription ? 'smartDescription' : 'Description'
              ] || '',
            gssnId: retailerSelected.value.GssnId
          }
        : {
            id: '',
            text: 'Showroom'
          }
    })

    const isSmart = computed<boolean>(
      () => props.vehicle?.Brand?.Description === 'SMART'
    )

    const isDisabled = computed<boolean>(
      () => isFetchingRetailer.value || props.isLoading
    )

    const dropdownItems = computed<IRetailerGroupDropdown[]>(() => {
      const filteredRetailerList = isSmart.value
        ? retailerItems.value.filter((retailer) => retailer.smart)
        : retailerItems.value

      return filteredRetailerList.map((retailer: IRetailer) => {
        const isSmartDescription = isSmart.value && retailer.smartDescription
        const retailerData = {
          id: retailer.Id || '',
          text:
            retailer[isSmartDescription ? 'smartDescription' : 'Description'] ||
            '',
          gssnId: retailer.GssnId
        }

        return {
          ...retailerData,
          onSelect: () => {
            retailerStore.updateRetailer(retailer)
          }
        }
      })
    })

    watch(dropdownItems, (val) => {
      if (!val.find((retailer) => retailerSelected.value?.Id === retailer.id)) {
        retailerStore.resetRetailer()
      }
    })

    onMounted(async () => {
      await fetchRetailerGroups()
    })

    const fetchRetailerGroups = async () => {
      if (!retailerSelected.value?.Id) return

      isFetchingRetailer.value = true

      const response = await $api.retailers.getGroup(retailerSelected.value.Id)

      if (response.data?.Retailers) {
        retailerItems.value = response.data.Retailers
      }

      isFetchingRetailer.value = false
    }

    const next = () => emit('next')

    return {
      isFetchingRetailer,
      dropdownItems,
      isDisabled,
      next,
      retailerSelectedDropdown,
      retailerSelected
    }
  }
}
</script>

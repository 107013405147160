<template>
  <div>
    <div class="os-form__section">
      <p class="os-form__subtitle">
        Your preferred Showroom will be in touch shortly.
      </p>
      <AccordionMenu single-item borderless :bold="false" class="mb-12">
        <AccordionItem>
          <AccordionItemTitle> Your test drive details </AccordionItemTitle>
          <AccordionItemContent class="pt-4">
            <div class="os-form__section">
              <p>
                {{ testDriveDate }}
              </p>
            </div>

            <div class="os-form__section">
              <div class="os-form__subtitle">Your {{ vehicle.Model }}</div>
              <FormsSectionVehicleSpecs :vehicle="vehicle" />
            </div>
            <div class="os-form__section">
              <OsPanel>
                <VehiclePricing :vehicle="vehicle" />
              </OsPanel>
            </div>
            <div v-if="hasOffers" class="os-form__section">
              <VehicleOffers
                :finance-offers="financeOffers"
                :non-monetary-offers="nonMonetaryOffers"
                :monetary-offers="monetaryOffers"
              />
            </div>
            <div v-if="retailer" class="os-form__section">
              <div class="os-form__subtitle">Your Showroom information</div>
              <RetailerInformation :retailer="retailer" :vehicle="vehicle" />
            </div>
          </AccordionItemContent>
        </AccordionItem>
      </AccordionMenu>

      <FormsSectionMarketingDeclaration v-if="isEnabled" />

      <FormsSectionLoginCard
        v-if="!isLoggedIn"
        class="os-form__section"
        :form-type="EFormType.TestDrive"
      />

      <SurveyMobile
        :form-type="formType"
        :vehicle="vehicle"
        :used-in-form="true"
        immediate
      />
    </div>

    <OsButtonTray v-if="!isLoggedIn">
      <OsLoginButton
        data-locator="login"
        :tracking-options="{
          category: `form-tray-${EFormType.TestDrive}`
        }"
        @click="onLogin"
      />
    </OsButtonTray>
  </div>
</template>

<script lang="ts">
import { useTestDriveStore } from '~/stores/forms/testDriveStore'
import { EFormType } from '~/types/forms'
import { IRetailer } from '~/types/retailer'
import { NewVehicle, UsedVehicle } from '~/types/vehicle'
import surveyQuestions from '~/constants/surveyQuestions'

export default {
  props: {
    stepSubtitle: { type: String, required: false, default: '' },
    vehicle: {
      type: Object as PropType<NewVehicle | UsedVehicle>,
      required: true
    },
    retailer: {
      type: Object as PropType<IRetailer>,
      required: false,
      default: () => ({})
    }
  },
  setup(props) {
    const testDriveStore = useTestDriveStore()
    const { getMonthName } = useDate()
    const { onLogin } = useCiamLogin()
    const formType = EFormType.TestDrive

    const testDriveDate = computed<string>(() => {
      const date = testDriveStore.date!
      const day = date.getDate() || ''
      const month = getMonthName(date)?.toUpperCase() || ''
      const year = date.getFullYear() || ''
      return `${month} ${day} ${year} (${
        testDriveStore.isAfternoon ? 'Afternoon' : 'Morning'
      })`
    })

    const { isLoggedIn } = useProfile()

    const { hasOffers, financeOffers, monetaryOffers, nonMonetaryOffers } =
      useVehicleOffers(toRef(props, 'vehicle'))

    const { isEnabled } = useFeatureFlag('onedoc_enabled')

    return {
      testDriveDate,
      isLoggedIn,
      EFormType,
      hasOffers,
      financeOffers,
      monetaryOffers,
      nonMonetaryOffers,
      onLogin,
      formType,
      surveyQuestions,
      isEnabled
    }
  }
}
</script>

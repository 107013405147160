<template>
  <div>
    <div class="car-details">
      <div v-if="list && list.length" class="os-form__section">
        <div v-if="listTitle" class="os-form__subtitle">
          {{ listTitle }}
        </div>

        <FormsSectionList :list="list" />
      </div>

      <div class="os-form__section">
        <div class="os-form__subtitle">Your car</div>
        <div v-if="!vehicle">
          <OsSkeletonLoader width="100%" height="252px" class="mb-2" />
          <OsSkeletonLoader
            class="vehicleOverviewContainer__skeleton__bottom"
            width="100%"
            height="50px"
          />
        </div>
        <div v-else>
          <div class="os-form__section">
            <FormsSectionVehicleSpecs :vehicle="vehicle" />
          </div>
          <div class="os-form__section">
            <OsPanel>
              <VehiclePricing :vehicle="vehicle" />
            </OsPanel>
          </div>
          <div v-if="hasOffers" class="os-form__section">
            <div class="os-form__subtitle">Additional Offers</div>
            <VehicleOffers
              :finance-offers="financeOffers"
              :non-monetary-offers="nonMonetaryOffers"
              :monetary-offers="monetaryOffers"
            />
          </div>
          <div v-if="showLiveChat" class="os-form__section">
            <OsLiveChat :vehicle="vehicle" />
          </div>
        </div>
      </div>
    </div>

    <OsButtonTray v-if="showSubmitButton">
      <OsButton is-primary @click.prevent="$emit('next')">
        {{ `Next: ${nextStepTitle}` }}
      </OsButton>
    </OsButtonTray>
  </div>
</template>

<script lang="ts">
import { NewVehicle, UsedVehicle } from '~/types/vehicle'

export default {
  props: {
    nextStepTitle: { type: String, required: false, default: '' },
    showSubmitButton: { type: Boolean, required: false, default: true },
    showLiveChat: { type: Boolean, required: false, default: false },
    listTitle: { type: String, required: false, default: '' },
    list: { type: Array, required: false, default: () => [] },
    vehicle: {
      type: Object as PropType<NewVehicle | UsedVehicle>,
      required: true
    }
  },

  emits: ['next'],

  setup(props) {
    const { hasOffers, financeOffers, monetaryOffers, nonMonetaryOffers } =
      useVehicleOffers(toRef(props, 'vehicle'))

    return {
      hasOffers,
      monetaryOffers,
      financeOffers,
      nonMonetaryOffers
    }
  }
}
</script>

<style scoped lang="scss">
.car-details {
  position: relative;
  min-height: 357px;
}
</style>

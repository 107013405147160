<template>
  <client-only>
    <FormsOcdCountdown />
    <FormsContent
      :current-step="currentStep"
      :has-error="hasError"
      :form-error-bag="formErrorBag"
      :form-type="formType"
      @start-again="resetForm"
    >
      <Form v-slot="{ meta }" as="section">
        <component
          :is="currentComponent"
          v-bind="{ invalid: !meta.valid, ...currentProps }"
          @next="next"
        >
          <template v-if="isStep(EFinanceEligibilitySteps.Showroom)" #top>
            <FormsSectionFinanceEligibilityBenefits />
          </template>
        </component>
      </Form>
    </FormsContent>
  </client-only>
</template>

<script lang="ts">
import { EFormType } from '~/types/forms'
import {
  EFinanceEligibilitySteps,
  useFinanceEligibilityStore
} from '~/stores/forms/financeEligibilityStore'
import { useFormsStore } from '~/stores/forms/formsStore'

import {
  FormsStepRetailer,
  FormsStepRetailerGroup,
  FormsOcdCreditCheck,
  FormsOcdFinanceApplication,
  FormsFinanceEligibilityThankYou
} from '#components'
import { useOnlineCreditDecisionStore } from '~/stores/forms/ocd/onlineCreditDecisionStore'

export default {
  name: 'FormsFinanceEligibility',

  setup() {
    const formsStore = useFormsStore()
    const financeEligibilityStore = useFinanceEligibilityStore()
    const ocdStore = useOnlineCreditDecisionStore()
    const formType = EFormType.FinanceEligibility
    const {
      currentStep,
      previousStep,
      nextStep,
      next: navigationNext
    } = financeEligibilityStore.navigation
    const { isPreviousStepLogin } = useCreateFormContainer(formType)

    const { trackPageView } = useFormTracking(formType, currentStep)

    onMounted(async () => {
      await ocdStore.fetchOcdSession()
      trackOptimizelyEvent(EOptimizelyEvent.OcdFormStart)
    })

    const componentDictionary = computed(() => ({
      [EFinanceEligibilitySteps.Showroom]: formsStore.isNewVehicle
        ? FormsStepRetailer
        : FormsStepRetailerGroup,
      [EFinanceEligibilitySteps.CheckFinance]: FormsOcdCreditCheck,
      [EFinanceEligibilitySteps.ApplyForFinance]: FormsOcdFinanceApplication,
      [EFinanceEligibilitySteps.ThankYou]: FormsFinanceEligibilityThankYou
    }))

    const { hasError, mappedSteps, isStep, currentComponent } =
      useOsForm<EFinanceEligibilitySteps>(
        financeEligibilityStore,
        componentDictionary
      )

    const trackFinanceEligibility = () => {
      if (
        [
          EFinanceEligibilitySteps.CheckFinance,
          EFinanceEligibilitySteps.ApplyForFinance
        ].includes(currentStep.value?.label as EFinanceEligibilitySteps)
      )
        return

      trackPageView({
        pageCategory: `ocd:${currentStep.value.label?.toLowerCase()}`
      })
    }

    watch(
      currentComponent,
      () => {
        trackFinanceEligibility()
      },
      { immediate: true }
    )

    const currentProps = computed(() => ({
      nextStepTitle: nextStep.value?.label,
      formType,
      vehicle: formsStore.getVehicle,
      isSmart: formsStore.isVehicleSmart
    }))

    const formErrorBag = ref(
      new FormErrorBag({
        buttonText: 'Start a new session'
      })
    )

    watchEffect(() => {
      isPreviousStepLogin.value =
        previousStep.value?.label === EFinanceEligibilitySteps.Login
    })

    watchEffect(() => {
      if (ocdStore.isSessionEnded) {
        formErrorBag.value.update({
          errorTitle: sessionExpiryError.title,
          errors: [sessionExpiryError.message!],
          trackingOptions: { pageCategory: 'ocd:timeout' }
        })
      } else {
        formErrorBag.value.update({
          trackingOptions: { pageCategory: 'ocd:error' }
        })
      }
    })

    const next = (skip?: number) => {
      navigationNext(skip)
    }

    const resetForm = async () => {
      ocdStore.resetOcdSession()
      financeEligibilityStore.resetWithNavigation()
      await ocdStore.fetchOcdSession()
    }

    return {
      formType,
      mappedSteps,
      currentProps,
      currentComponent,
      next,
      resetForm,
      isStep,
      EFinanceEligibilitySteps,
      currentStep,
      formErrorBag,
      hasError
    }
  }
}
</script>

<template>
  <div class="os-form__section">
    <InputRadioButton
      v-for="(option, index) in forms"
      :key="index"
      :is-row="true"
      :hide-circle="true"
      :is-active="isActive(option)"
      :disabled="option.disabled"
      class="radioButton text-sm border"
      :class="{
        'radioButton--blue': index === 0,
        'border-gray-50 bg-gray-50': index > 0
      }"
      @event="onOptionSelected(option)"
    >
      <div class="mr-6">
        <h6 v-html="option.title" />
        <p v-html="option.summary" />
        <p v-if="option.description" v-html="option.description" />
      </div>
      <OsIcon
        class="absolute top-1/2 right-4 -rotate-90 text-white -mt-1"
        :class="{ 'text--blue': index > 0 }"
        name="Arrow"
      />
    </InputRadioButton>
  </div>
</template>

<script lang="ts">
import { ICallToActionDirectoryOption } from '~/types/forms'

export default {
  name: 'CtaDirectoryButtons',
  props: {
    modelValue: {
      type: Object as PropType<ICallToActionDirectoryOption>,
      required: false,
      default: undefined
    },
    forms: {
      type: Array as PropType<ICallToActionDirectoryOption[]>,
      required: true
    }
  },

  emits: ['update:modelValue', 'option-selected'],

  setup(props, { emit }) {
    const formSelected = computed<ICallToActionDirectoryOption | undefined>({
      get() {
        return props.modelValue
      },
      set(val) {
        emit('update:modelValue', val)
      }
    })

    const isActive = (option: ICallToActionDirectoryOption) => {
      return formSelected.value?.title === option.title
    }

    const onOptionSelected = (option: ICallToActionDirectoryOption) => {
      if (option.disabled) return

      formSelected.value = option
      emit('option-selected', option)
    }

    return {
      formSelected,
      isActive,
      onOptionSelected
    }
  }
}
</script>
<style lang="scss" scoped>
:deep(.radioButton.radioButton--blue) {
  background-color: $blue;
  color: $white;
  h6 {
    color: $white;
  }
}
:deep(.radioButton) {
  background-color: $grey--lightest;
  border: 1px solid $grey--lightest;
  box-shadow: $blue 0px 0px 0px;
  &:hover {
    box-shadow: $blue 0px 0px 2px;
  }
  ul {
    list-style: disc;
    padding-left: rem(16);
    padding-right: rem(16);
    font-size: rem(14);
  }
}
</style>

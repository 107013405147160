<template>
  <InputCheckbox v-model="checkboxModel" :label="field.Label" />
</template>

<script lang="ts">
import { IOcdField } from '~/types/forms'

export default {
  name: 'OcdFormFactoryCheckbox',
  props: {
    field: {
      type: Object as PropType<IOcdField>,
      required: true
    }
  },
  setup(props) {
    const { updateFormValue, getFieldValue } = useFormFactory()

    const checkboxModel = computed({
      get() {
        return !!getFieldValue(props.field)
      },
      set(val) {
        updateFormValue({
          field: props.field,
          value: val ? 'true' : ''
        })
      }
    })

    return { checkboxModel }
  }
}
</script>

<template>
  <div class="formFactory">
    <section :key="`formFactorySection${currentStep.key}`">
      <FormsOcdFormFactorySection
        v-for="section in currentStepSections"
        :key="section.Identifier"
        :section="section"
      />

      <slot />

      <OsButtonTray>
        <OsButton
          is-primary
          :is-loading="isLoading"
          :is-disabled="!canSubmit || isLoading"
          @click.prevent="next"
        >
          {{ nextButtonTitle }}
        </OsButton>
      </OsButtonTray>
    </section>
  </div>
</template>

<script lang="ts">
import { useFormFactoryStore } from '~/stores/forms/ocd/formFactoryStore'
import { useRetailerStore } from '~/stores/retailerStore'

export default {
  props: {
    nextStepTitle: {
      type: String,
      required: false,
      default: ''
    },
    trackingFormName: {
      type: String,
      required: false,
      default: 'FormFactory'
    },
    trackingPageCategoryPrefix: {
      type: String,
      required: false,
      default: ''
    }
  },

  emits: ['formFactoryFinished'],

  setup(props, { emit }) {
    const ocdFormFactoryStore = useFormFactoryStore()
    const retailerStore = useRetailerStore()
    const { $dataLayer } = useNuxtApp()
    const { backFn } = useFormContainer()
    const { scrollSidepanelToTop } = useSidepanel(ESidepanel.FORMS)

    const currentStepSections = computed(
      () => ocdFormFactoryStore.getCurrentStepSections
    )

    const canSubmit = computed(
      () => !currentStepSections.value.find((s) => !s.IsValidToSubmit)
    )

    const {
      currentStep,
      next: navigationNext,
      isLastStep,
      nextStep
    } = ocdFormFactoryStore.navigation

    const isLoading = computed<boolean>(
      () => ocdFormFactoryStore.isFormFactoryLoading
    )

    const nextButtonTitle = computed<string>(() => {
      if (isLoading.value) return 'Please wait'

      return isLastStep.value
        ? props.nextStepTitle
        : `NEXT: ${nextStep.value?.label}`
    })

    const next = () => {
      if (isLastStep.value) {
        emit('formFactoryFinished')
      } else {
        navigationNext()
        ocdFormFactoryStore.resetTextFieldsValue()
      }
    }

    watch(
      ocdFormFactoryStore.navigation.isFirstStep,
      (val) => {
        backFn.value = val ? undefined : ocdFormFactoryStore.navigation.back
      },
      { immediate: true }
    )

    onBeforeUnmount(() => {
      backFn.value = undefined
    })

    watch(
      currentStepSections,
      (val, oldVal) => {
        if (val && oldVal && val[0]?.Identifier === oldVal[0]?.Identifier)
          return

        scrollSidepanelToTop()
        $dataLayer.pageView({
          pageCategory: `${props.trackingFormName}:${
            props.trackingPageCategoryPrefix
          }${currentStep.value.label.toLowerCase()}`,
          user: {
            preferred_retailer: retailerStore.retailer?.Description || ''
          }
        })
      },
      { immediate: true }
    )

    return {
      currentStep,
      next,
      isLoading,
      nextButtonTitle,
      currentStepSections,
      canSubmit
    }
  }
}
</script>

<style lang="scss" scoped>
@import './assets/styles/tools/_forms.scss';
.formFactory {
  &:deep(.button-tray) {
    z-index: unset;
  }

  &:deep(.form-factory__disclaimer) {
    font-size: rem(14);
    color: $grey--darkest;
    margin-bottom: rem(16);
    a {
      @extend .os-form__link !optional;
    }
    p {
      font-size: rem(14);
      margin-bottom: rem(14);
    }
  }
}
</style>

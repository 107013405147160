<template>
  <div class="form-section">
    <FormsOcdFormFactorySectionTitle
      :title="section.Label"
      :section="section"
    />

    <div v-if="section.Fields.length" class="form-fields">
      <div
        v-for="field in section.Fields"
        :key="field.Identifier"
        class="form-field"
      >
        <p v-if="field.ToolTip" class="form-field__tooltip">
          {{ field.ToolTip }}
        </p>

        <template v-if="field.Type === EOcdFieldType.Select">
          <FormsOcdFormFactoryInputRadio
            v-if="field.Key === 'Settlement'"
            :field="field"
          />
          <FormsOcdFormFactoryInputDropdown v-else :field="field" />
        </template>

        <template v-else-if="field.Type === EOcdFieldType.Postcode">
          <FormsOcdFormFactoryInputPostcode :field="field" />
        </template>

        <template v-else-if="field.Type === EOcdFieldType.DisplayText">
          <FormsOcdFinanceNotice
            v-if="field.Identifier === 'OS_NOTICE'"
            :field="field"
          />
          <p v-else class="display-text-field" v-html="field.Value"></p>
        </template>

        <template v-else-if="field.Type === EOcdFieldType.CheckBox">
          <FormsOcdFormFactoryInputCheckbox :field="field" />
        </template>

        <template v-else-if="field.Type === EOcdFieldType.Number">
          <FormsOcdFormFactoryInputNumber :field="field" />
        </template>

        <template v-else-if="field.Type === EOcdFieldType.Date">
          <FormsOcdFormFactoryInputDate :field="field" />
        </template>

        <div v-else>
          <FormsOcdFormFactoryInputText :field="field" />
        </div>
      </div>
    </div>

    <!-- TODO: should we add the footer actions here or under the section.Sections?
      <SectionTitle
      v-if="section.Actions.Footer.length"
      :actions="section.Actions.Footer"
    /> -->

    <template v-if="section.Sections.length">
      <FormsOcdFormFactorySection
        v-for="subSection in section.Sections"
        :key="subSection.Identifier"
        :section="subSection"
      />
    </template>

    <slot />
  </div>
</template>

<script lang="ts">
import { EOcdFieldType, IOcdSection } from '~/types/forms'

export default {
  name: 'OcdFormFactorySection',
  props: {
    section: {
      type: Object as PropType<IOcdSection>,
      required: true
    }
  },
  setup() {
    return { EOcdFieldType }
  }
}
</script>

<style lang="scss" scoped>
.form-section {
  // when we join 2 steps
  & + .form-section {
    margin-top: rem(64);
  }

  &:deep(.form-title) {
    @include formHeading();
  }

  .form-section:deep(.form-title) {
    // form subtitle because it's a form-section within another form-section

    @include sectionHeading();
    align-items: center;
    display: flex;
    justify-content: space-between;

    span {
      color: $grey--dark;
      font-family: $mb-font-text;
      font-size: rem(14);
    }
  }

  .form-fields {
    .form-field {
      margin-bottom: rem(16);

      .display-text-field {
        font-size: rem(14);
      }

      &:deep(.dropdown) {
        z-index: unset;

        .dropdownItem--trigger {
          z-index: unset;
        }
      }
    }

    .form-field__tooltip {
      font-family: $mb-font-text;
      font-size: rem(14);
      color: $grey--darkest;
      margin-bottom: rem(16);

      a {
        color: $blue;
      }
    }
  }
}
</style>

<template>
  <InputDropdown :model-value="selectedItem" :items="dropdownItems" required />
</template>

<script lang="ts">
import { IOcdField } from '~/types/forms'

export default {
  name: 'OcdFormFactoryDropdown',
  props: {
    field: {
      type: Object as PropType<IOcdField>,
      required: true
    }
  },
  setup(props) {
    const { updateFormValue } = useFormFactory()

    const selectedItem = computed(() => {
      const selectedOption = props.field.Options.find(
        (ele) => ele.Value === props.field.Value
      )
      return {
        id: -1,
        text: (selectedOption && selectedOption.Text) || props.field.Label
      }
    })

    const dropdownItems = computed(() =>
      props.field.Options.map((ele) => {
        return {
          id: ele.Value,
          text: ele.Text,
          onSelect: () => {
            updateFormValue({ field: props.field, value: ele.Value })
          }
        }
      })
    )

    return { selectedItem, dropdownItems }
  }
}
</script>

<template>
  <div v-click-outside="close">
    <InputLocation
      :results="addresses"
      :name="field.Identifier"
      :model-value="fieldValue"
      :validate="isValidateField"
      :errors="fieldErrors"
      :data-saved="fieldValue.length > 0"
      :label="field.Label"
      :is-loading="isLoading"
      :location-enabled="false"
      :rules="'required'"
      @update:model-value="handleInput"
      @selected="selectAddress"
    >
      <template #default="{ result }">
        <span>{{ result.Description }}</span>
      </template>
    </InputLocation>
    <OsError v-if="fieldErrors.length > 0">
      {{ fieldErrors[0] }}
    </OsError>
  </div>
</template>

<script lang="ts">
import clickOutside from '~/directives/clickOutside'
import { useFormFactoryStore } from '~/stores/forms/ocd/formFactoryStore'
import { useOnlineCreditDecisionStore } from '~/stores/forms/ocd/onlineCreditDecisionStore'

import { IOcdField } from '~/types/forms'

export default {
  name: 'OcdFormFactoryPostcode',

  directives: {
    clickOutside
  },

  props: {
    field: {
      type: Object as PropType<IOcdField>,
      required: true
    }
  },
  setup(props) {
    const {
      getFieldErrors,
      getFieldValue,
      isValidateField: isValidate
    } = useFormFactory()

    const ocdStore = useOnlineCreditDecisionStore()
    const ocdFormFactoryStore = useFormFactoryStore()

    const isLoading = ref<boolean>(false)
    const addresses = ref<any[]>([])

    const fieldValue = computed(() => getFieldValue(props.field))
    const fieldErrors = computed(() => getFieldErrors(props.field))
    const isValidateField = computed(() => isValidate(props.field))

    const handleInput = async (value: string) => {
      if (value === fieldValue.value) return

      close()

      await ocdFormFactoryStore.updateFormValue({ field: props.field, value })

      if (fieldErrors.value.length > 0) return

      await search(value)
    }

    const search = async (value: string) => {
      if (isLoading.value) return

      isLoading.value = true

      addresses.value = await ocdStore.getAddressList(value)

      isLoading.value = false
    }

    const selectAddress = (address: any) => {
      ocdStore.updateFormAddress({
        data: address,
        id: props.field.Identifier
      })

      close()
    }

    const close = () => {
      addresses.value = []
    }

    return {
      isLoading,
      addresses,
      fieldValue,
      fieldErrors,
      handleInput,
      selectAddress,
      isValidateField,
      close
    }
  }
}
</script>

<template>
  <FormsContent
    :steps="mappedSteps"
    :has-error="hasError"
    :current-step="currentStep"
    :form-type="formType"
    @start-again="resetForm"
  >
    <Form v-slot="{ meta }" as="section">
      <div class="os-form__section">
        <component
          :is="currentComponent"
          v-bind="{ invalid: !meta.valid, ...currentProps }"
          @next="next"
        >
          <template
            v-if="isStep(ECallbackSteps.ContactForm)"
            #button-tray="{ valid }"
          >
            <OsButtonTray>
              <OsButton
                :is-disabled="isLoading || !valid"
                :is-loading="isLoading"
                is-primary
                :aria-label="
                  isLoading ? 'Please wait' : ' Request a stock notification'
                "
                @click.prevent="submit"
              >
                {{
                  isLoading ? 'Please wait' : ' Request a stock notification'
                }}
              </OsButton>
            </OsButtonTray>
          </template>
        </component>
      </div>
    </Form>
  </FormsContent>
</template>

<script lang="ts">
import { ECallbackSteps, useCallbackStore } from '~/stores/forms/callbackStore'
import { EFormType } from '~/types/forms'
import { useFormsStore } from '~/stores/forms/formsStore'

import { useCreateFormContainer } from '~/composables/forms/useFormContainer'

import {
  FormsStepLogin,
  FormsStepRetailer,
  FormsStepCustomerDetails,
  FormsCallbackThankYou
} from '#components'

export default {
  setup() {
    const callbackStore = useCallbackStore()
    const formsStore = useFormsStore()
    const { initTalkingTag } = useFlashTalking()
    const { $dataLayer } = useNuxtApp()

    const formType = EFormType.Callback
    const { currentStep, nextStep, next, previousStep } =
      callbackStore.navigation
    const { trackPageView } = useFormTracking(formType, currentStep)
    const { isPreviousStepLogin } = useCreateFormContainer(formType)

    onBeforeMount(() => {
      callbackStore.resetWithNavigation()
      trackPageView()
    })

    const isLoading = ref<boolean>(false)
    const componentDictionary = computed(() => ({
      [ECallbackSteps.Login]: FormsStepLogin,
      [ECallbackSteps.StockNotification]: FormsStepRetailer,
      [ECallbackSteps.ContactForm]: FormsStepCustomerDetails,
      [ECallbackSteps.ThankYou]: FormsCallbackThankYou
    }))

    const { hasError, mappedSteps, isStep, currentComponent } =
      useOsForm<ECallbackSteps>(callbackStore, componentDictionary)

    const currentProps = computed(() => {
      return {
        formType,
        stepTitle: currentStep.value?.title,
        nextStepTitle: nextStep.value?.label,
        vehicle: formsStore.getVehicle,
        hasAddress: false,
        isSmart: callbackStore.isSmart,
        isLoading: isLoading.value
        // retailer: retailerStore.retailer
      }
    })

    watch(currentComponent, () => trackPageView())

    watchEffect(() => {
      isPreviousStepLogin.value =
        previousStep.value?.label === ECallbackSteps.Login
    })

    const submit = async () => {
      isLoading.value = true
      const response: any = await callbackStore.submit()
      isLoading.value = false
      initTalkingTag('112997', 'Send_Callback')

      if (response.data?.requestProcessorResponse?.statusCode === 200) {
        next()
      }

      $dataLayer.linkClick({
        pageCategory: 'forms',
        category: 'forms:callback',
        action: 'callback'
      })
    }

    return {
      formType,
      currentComponent,
      currentProps,
      mappedSteps,
      next,
      isLoading,
      hasError,
      currentStep,
      resetForm: callbackStore.resetWithNavigation,
      submit,
      ECallbackSteps,
      isStep
    }
  }
}
</script>

<template>
  <FormsContent
    :current-step="currentStep"
    :form-type="formType"
    :form-error-bag="new FormErrorBag({ showStartAgainButton: false })"
  >
    <template #steps>
      <span />
    </template>
    <div class="os-form__section">
      <component
        :is="currentComponent"
        v-bind="{ ...currentProps, invalid }"
        tracking-form-name="ocd"
        @form-factory-finished="submitCreditCheck"
        @next="next"
      >
        <div
          v-if="isFormFactoryAddressStep"
          class="form-factory__disclaimer"
          v-html="preCreditText.replace('{{BUTTON_TEXT}}', 'CHECK NOW')"
        ></div>
      </component>
    </div>
  </FormsContent>
</template>

<script lang="ts">
import { NewVehicle, UsedVehicle } from '~/types/vehicle'

import {
  ECreditCheckSteps,
  useCreditCheckStore
} from '~/stores/forms/ocd/creditCheckStore'
import { EFormType, EOcdLabel } from '~/types/forms'

import {
  FormsOcdCreditCheckFinanceQuote,
  FormsOcdCreditCheckFinancePrivacy,
  FormsOcdFormFactory,
  FormsOcdCreditCheckFinanceResult
} from '#components'
import { useFormFactoryStore } from '~/stores/forms/ocd/formFactoryStore'
import { useOnlineCreditDecisionStore } from '~/stores/forms/ocd/onlineCreditDecisionStore'

export default {
  props: {
    vehicle: {
      type: Object as PropType<NewVehicle | UsedVehicle>,
      required: false,
      default: () => ({})
    },
    invalid: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['next'],

  setup(props, { emit }) {
    const creditCheckStore = useCreditCheckStore()
    const ocdFormFactoryStore = useFormFactoryStore()
    const ocdStore = useOnlineCreditDecisionStore()
    const { $dataLayer } = useNuxtApp()
    const { scrollSidepanelToTop } = useSidepanel(ESidepanel.FORMS)
    const {
      currentStep,
      isLastStep,
      next: navigationNext,
      nextStep
    } = creditCheckStore.navigation

    const formType = EFormType.FinanceEligibility
    const { currentStep: formFactoryCurrentStep } =
      ocdFormFactoryStore.navigation
    const { trackPageView } = useFormTracking(formType, currentStep)
    const { isBackDisabled, backFn } = useFormContainer()

    const preCreditText = computed(() => ocdStore.content.preCreditText)

    const componentDictionary = computed(() => ({
      [ECreditCheckSteps.FinanceQuote]: FormsOcdCreditCheckFinanceQuote,
      [ECreditCheckSteps.FinanceEligibility]: FormsOcdCreditCheckFinancePrivacy,
      [ECreditCheckSteps.FormFactory]: FormsOcdFormFactory,
      [ECreditCheckSteps.FinanceEligibilityResult]:
        FormsOcdCreditCheckFinanceResult
    }))

    const currentComponent = computed<any>(
      () =>
        componentDictionary.value[currentStep.value?.label as ECreditCheckSteps]
    )

    const isStep = (step: ECreditCheckSteps): boolean =>
      currentComponent.value === componentDictionary.value[step]

    const next = (skipSteps?: number) => {
      if (isLastStep.value) return emit('next', skipSteps)

      navigationNext(skipSteps)
    }

    const submitCreditCheck = async () => {
      $dataLayer.linkClick({
        pageCategory: `ocd:${formFactoryCurrentStep.value.label?.toLowerCase()}`,
        category: `ocd_form`,
        action: 'finance eligibility result'
      })

      next()

      await creditCheckStore.submitForm()
      await creditCheckStore.fetchCreditRating()
    }

    const currentProps = computed(() => ({
      vehicle: props.vehicle,
      steps: ocdFormFactoryStore.getSteps,
      nextStepTitle: nextStep.value?.label
    }))

    const isFormFactoryAddressStep = computed(
      () =>
        isStep(ECreditCheckSteps.FormFactory) &&
        formFactoryCurrentStep.value.key === EOcdLabel.ADDRESS_HISTORY &&
        preCreditText.value
    )

    watchEffect(() => {
      switch (currentStep.value?.label) {
        case ECreditCheckSteps.FinanceQuote:
        case ECreditCheckSteps.FinanceEligibilityResult:
          backFn.value = undefined
          break
        case ECreditCheckSteps.FinanceEligibility:
          nextTick(() => {
            backFn.value = creditCheckStore.navigation.back
          })
          break
      }
    })

    watchEffect(() => {
      const isFormFactoryFirstStep =
        ocdFormFactoryStore.navigation.isFirstStep.value
      if (
        isFormFactoryFirstStep &&
        currentStep.value?.label === ECreditCheckSteps.FormFactory
      ) {
        nextTick(() => {
          backFn.value = creditCheckStore.navigation.back
        })
      }
    })

    onBeforeUnmount(() => {
      isBackDisabled.value = false
      backFn.value = undefined
    })

    watch(
      currentComponent,
      () => {
        scrollSidepanelToTop()

        // these two steps are tracked in their own components
        if (
          [
            ECreditCheckSteps.FormFactory,
            ECreditCheckSteps.FinanceEligibilityResult
          ].includes(currentStep.value.label as ECreditCheckSteps)
        )
          return
        trackPageView({
          pageCategory: `ocd:${currentStep.value.label.toLowerCase()}`
        })
      },
      { immediate: true }
    )

    return {
      formType,
      currentStep,
      componentDictionary,
      currentComponent,
      next,
      submitCreditCheck,
      currentProps,
      isFormFactoryAddressStep,
      preCreditText
    }
  }
}
</script>

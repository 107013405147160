<template>
  <div class="flex ml-10">
    <OsDropdown v-model:open="open" :items="myAccountItems" class="dark">
      <button
        aria-label="Log into My Account"
        class="hidden text-lg w-32 lg:block"
        :class="{ 'font-bold': open }"
      >
        My Account
        <OsIcon name="arrow" class="text-blue-600 ml-1 align-sub" />
      </button>

      <button aria-label="more options" class="lg:hidden">
        <UChip :ui="{ base: 'ring-0' }" :show="notificationsCount > 0">
          <OsIcon name="Person" class="text-white" />
        </UChip>
      </button>
    </OsDropdown>
  </div>
</template>

<script lang="ts">
import type { OsDropdownItem } from '#os-ui/types'
import { useShortlistStore } from '~/stores/shortlistStore'
import { useSavedSearchStore } from '~/stores/savedSearchStore'
import { useProfileStore } from '~/stores/profileStore'
import { EServiceNames } from '~/types/myaccount/services'
import { ERoutes } from '~/types/routes'

export default {
  setup() {
    const { $dataLayer } = useNuxtApp()

    const { onLogin, logoutUrl } = useCiamLogin()

    const profileStore = useProfileStore()
    const shortlistStore = useShortlistStore()
    const savedSearchStore = useSavedSearchStore()

    const isLoggedIn = computed(() => profileStore.isLoggedIn)

    const track = (label: string, count?: string | number) => {
      $dataLayer.linkClick({
        category: 'my_account_dropdown',
        action: `${label} ${count ?? '0'}`
      })
    }

    // TODO: Create one list for My Account service and two transforms.
    // One for the dropdown and one for the sidebar.

    const {
      Orders,
      RecentlyViewed,
      Shortlist,
      SavedSearch,
      Enquiries,
      TestDrives,
      Valuations,
      BackOrders
    } = EServiceNames

    const myAccountItems = computed(() => {
      const items: OsDropdownItem[] = [
        {
          label: 'Go to My Account',
          icon: 'person',
          iconClass: 'text-blue-600',
          class:
            'font-bold text-gray-900 dark:ui-open:bg-transparent dark:ui-active:bg-gray-900',
          click: () => track('Go to My Account'),
          to: { name: ERoutes.MyAccount }
        },
        {
          label: 'Orders',
          icon: 'cart',
          iconClass: hasCount(Orders) ? 'text-blue-600' : 'text-gray-400',
          class: hasCount(Orders) ? 'font-bold text-gray-900' : '',
          suffix: serviceCount(Orders),
          click: () => track('Orders', serviceCount(Orders)),
          to: { name: ERoutes.MyAccountOrders }
        },
        {
          label: 'Recently viewed',
          icon: 'carMenu',
          iconClass: hasCount(RecentlyViewed)
            ? 'text-blue-600'
            : 'text-gray-400',
          class: hasCount(RecentlyViewed) ? 'font-bold text-gray-900' : '',
          suffix: serviceCount(RecentlyViewed),
          click: () => track('Recently viewed', serviceCount(RecentlyViewed)),
          to: { name: ERoutes.MyAccountRecentlyViewed }
        },
        {
          label: 'Shortlist',
          icon: 'heart',
          iconClass: hasCount(Shortlist) ? 'text-blue-600' : 'text-gray-400',
          class: hasCount(Shortlist) ? 'font-bold text-gray-900' : '',
          suffix: serviceCount(Shortlist),
          click: () => track('Shortlist', serviceCount(Shortlist)),
          to: { name: ERoutes.MyAccountShortlist }
        },
        {
          label: 'Saved searches',
          icon: 'carSearch',
          iconClass: hasCount(SavedSearch) ? 'text-blue-600' : 'text-gray-400',
          class: hasCount(SavedSearch) ? 'font-bold text-gray-900' : '',
          suffix: serviceCount(SavedSearch),
          click: () => track('Saved searches', serviceCount(SavedSearch)),
          to: { name: ERoutes.MyAccountSavedSearch }
        }
      ]

      const loggedOutItems = [
        {
          label: 'Log in / Sign up',
          icon: 'person',
          class:
            'bg-blue-600 text-white py-3 font-bold justify-center dark:ui-active:bg-blue-500',
          click: () => onLogin()
        }
      ]

      const loggedInItems = [
        {
          label: 'Enquiries',
          icon: 'car',
          iconClass: hasCount(Enquiries) ? 'text-blue-600' : 'text-gray-400',
          class: hasCount(Enquiries) ? 'font-bold text-gray-900' : '',
          suffix: serviceCount(Enquiries),
          click: () => track('Enquiries', serviceCount(Enquiries)),
          to: { name: ERoutes.MyAccountEnquiries }
        },
        {
          label: 'Test drives',
          icon: 'steeringWheel',
          iconClass: hasCount(TestDrives) ? 'text-blue-600' : 'text-gray-400',
          class: hasCount(TestDrives) ? 'font-bold text-gray-900' : '',
          suffix: serviceCount(TestDrives),
          click: () => track('Test drives', serviceCount(TestDrives)),
          to: { name: ERoutes.MyAccountTestDrives }
        },
        {
          label: 'Car valuations',
          icon: 'pound',
          iconClass: hasCount(Valuations) ? 'text-blue-600' : 'text-gray-400',
          class: hasCount(Valuations) ? 'font-bold text-gray-900' : '',
          suffix: serviceCount(Valuations),
          click: () => track('Car valuations', serviceCount(Valuations)),
          to: { name: ERoutes.MyAccountValuations }
        },
        {
          label: 'Stock notifications',
          icon: 'carPrice',
          iconClass: hasCount(BackOrders) ? 'text-blue-600' : 'text-gray-400',
          class: hasCount(BackOrders) ? 'font-bold text-gray-900' : '',
          suffix: serviceCount(BackOrders),
          click: () => track('Stock notifications', serviceCount(BackOrders)),
          to: { name: ERoutes.MyAccountBackOrders }
        },
        {
          label: 'Settings',
          icon: 'settings',
          class: 'w-1/2 justify-center px-0',
          click: () => track('Settings'),
          to: { name: ERoutes.MyAccountSettings }
        },
        {
          label: 'Log out',
          icon: 'person',
          class: 'w-1/2 justify-center px-0',
          to: logoutUrl
        }
      ]

      return isLoggedIn.value
        ? [items.concat(loggedInItems)]
        : [items.concat(loggedOutItems)]
    })

    const notificationsCount = computed<number>(
      () => shortlistStore.getCount + savedSearchStore.getSearchesCount
    )

    const open = ref(false)

    watch(open, (value) => {
      $dataLayer.linkClick({
        category: 'global_header',
        action: `my_account_dropdown_:${value ? 'open' : 'close'}`
      })

      if (value) fetchCounts()
    })

    const { $api } = useNuxtApp()

    // TODO: Move this and MyAccount version to composable
    const { data, refresh: fetchCounts } = useCustomLazyAsyncData(
      'myaccount-services',
      async () => {
        const response = await $api.myAccount.count.get()
        return { data: formatCount(useCloneDeep(response.data)), error: null }
      },
      { immediate: false }
    )

    // TODO: Move this and MyAccount version to composable
    const serviceCount = (
      label: EServiceNames
    ): string | number | undefined => {
      if (label === EServiceNames.Settings) {
        return ''
      }

      if (label === EServiceNames.Shortlist) {
        return shortlistStore.getCount || data.value?.[label]
      }

      return data.value?.[label]
    }

    const hasCount = (label: EServiceNames) => {
      if (!label) return false

      const count = serviceCount(label)

      return Number(count) > 0
    }

    return {
      notificationsCount,
      myAccountItems,
      isLoggedIn,
      onLogin,
      open,
      serviceCount,
      hasCount
    }
  }
}
</script>

<template>
  <transition name="slideDown">
    <div v-if="isCountingDown" class="mb-10">
      <p>
        If you remain inactive, this session will timeout in
        <b>{{ countdown }}</b>
      </p>
      <OsButton
        is-secondary
        class="os-button--block mt-4"
        @click.stop.prevent="onExtendSession"
      >
        <OsIcon name="Clock" size="0.825rem" class="text-blue-600" />Need more
        time?
      </OsButton>
    </div>
  </transition>
</template>
<script lang="ts">
import { useFormFactoryStore } from '~/stores/forms/ocd/formFactoryStore'
import { useOnlineCreditDecisionStore } from '~/stores/forms/ocd/onlineCreditDecisionStore'
export default {
  setup() {
    const { $dataLayer } = useNuxtApp()
    const { scrollSidepanelToTop } = useSidepanel(ESidepanel.FORMS)
    const ocdStore = useOnlineCreditDecisionStore()
    const ocdFormFactoryStore = useFormFactoryStore()

    const countdown = computed(() => ocdStore.sessionEnding)
    const isCountingDown = computed(() => {
      return !!countdown.value
    })

    const pageCategory = computed(() => {
      return `ocd:${formFactoryCurrentStep.value.label?.toLowerCase()}`
    })

    const onExtendSession = () => {
      ocdStore.extendSession()
      $dataLayer.linkClick({
        category: 'ocd_timeout_extend_session',
        pageCategory: pageCategory.value
      })
    }

    const { currentStep: formFactoryCurrentStep } =
      ocdFormFactoryStore.navigation

    watch(isCountingDown, (val) => {
      if (val) {
        if (window && window.document.hasFocus()) {
          $dataLayer.eecImpression({
            category: 'ocd_timeout_warning_seen',
            pageCategory: pageCategory.value
          })
        } else {
          $dataLayer.systemMessageEvent({
            category: 'ocd_timeout_warning',
            pageCategory: pageCategory.value
          })
        }
        scrollSidepanelToTop()
      }
    })

    return {
      onExtendSession,
      isCountingDown,
      countdown
    }
  }
}
</script>

<style lang="scss" scoped>
.notification {
  display: block;
}
</style>

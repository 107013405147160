<template>
  <FormsContent
    :steps="mappedSteps"
    :has-error="hasError"
    :current-step="currentStep"
    :form-type="formType"
    @start-again="resetForm"
  >
    <Form v-slot="{ meta }" as="section">
      <div class="os-form__section">
        <component
          :is="currentComponent"
          v-bind="{ invalid: !meta.valid, ...currentProps }"
          @next="next"
        >
          <template
            v-if="isStep(ETestDriveSteps.ContactForm)"
            #button-tray="{ valid }"
          >
            <OsButtonTray>
              <OsButton
                :is-disabled="isLoading || !valid"
                is-primary
                @click.prevent="submit"
              >
                {{ `Next: ${nextStepTitle}` }}
              </OsButton>
            </OsButtonTray>
          </template>
        </component>
      </div>
    </Form>
  </FormsContent>
</template>

<script lang="ts">
import { EFormType } from '~/types/forms'
import { useFormsStore } from '~/stores/forms/formsStore'
import {
  ETestDriveSteps,
  useTestDriveStore
} from '~/stores/forms/testDriveStore'
import { useCustomerTypeStore } from '~/stores/customerTypeStore'
import { useRetailerStore } from '~/stores/retailerStore'
import {
  FormsSectionVehicleDetails,
  FormsTestDriveLocation,
  FormsTestDriveCalendar,
  FormsStepCustomerDetails,
  FormsTestDriveThankYou
} from '#components'

export default {
  setup() {
    const formsStore = useFormsStore()
    const retailerStore = useRetailerStore()
    const testDriveStore = useTestDriveStore()
    const config = useRuntimeConfig()
    const router = useRouter()
    const route = useRoute()
    const { getCustomerTypeName } = useCustomerTypeStore()
    const formType = EFormType.TestDrive
    const { currentStep, nextStep, next } = testDriveStore.navigation
    useCreateFormContainer(formType)

    const { initTalkingTag } = useFlashTalking()
    const { trackPageView } = useFormTracking(formType, currentStep)

    onMounted(() => {
      testDriveStore.resetWithNavigation()
      trackPageView()
      trackOptimizelyEvent(EOptimizelyEvent.TestDriveStart)
    })

    const isLoading = ref<boolean>(false)

    const componentDictionary = computed(() => ({
      [ETestDriveSteps.RequestTestDrive]: FormsSectionVehicleDetails,
      [ETestDriveSteps.Location]: FormsTestDriveLocation,
      [ETestDriveSteps.PickDate]: FormsTestDriveCalendar,
      [ETestDriveSteps.ContactForm]: FormsStepCustomerDetails,
      [ETestDriveSteps.ThankYou]: FormsTestDriveThankYou
    }))

    const { hasError, mappedSteps, isStep, currentComponent } =
      useOsForm<ETestDriveSteps>(testDriveStore, componentDictionary)

    const nextStepTitle = computed(() => nextStep.value?.label)

    const currentProps = computed(() => {
      return {
        formType,
        stepTitle: currentStep.value?.title,
        nextStepTitle: nextStepTitle.value,
        vehicle: formsStore.getVehicle,
        isMBCUKRetailer: formsStore.isVehicleMBCUKRetailer,
        hasAddress: false,
        isLoading: isLoading.value,
        retailer: retailerStore.retailer,
        isNew: formsStore.isNewVehicle,
        isSmart: formsStore.isVehicleSmart
      }
    })

    const submit = async () => {
      if (!retailerStore.retailer) return

      const detailsUrl = getVehicleDetailsUrl(
        formsStore.getVehicle!,
        getCustomerTypeName,
        route,
        router,
        config.public.baseUrl
      )

      isLoading.value = true

      const response: any = await testDriveStore.submit(detailsUrl)

      isLoading.value = false

      initTalkingTag('112997', 'Send_TestDrive')

      if (response.data?.requestProcessorResponse?.statusCode === 200) {
        next()
      }

      trackOptimizelyEvent(EOptimizelyEvent.TestDriveComplete)
    }

    watch(currentComponent, () => trackPageView())

    return {
      formType,
      mappedSteps,
      currentStep,
      resetForm: testDriveStore.resetWithNavigation,
      currentComponent,
      next,
      submit,
      currentProps,
      isLoading,
      isStep,
      ETestDriveSteps,
      hasError,
      nextStepTitle
    }
  }
}
</script>

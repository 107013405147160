<template>
  <section class="os-form__section">
    <form ref="form" novalidate>
      <div class="os-form__section">
        <p class="os-form__text-content">
          Receive an estimate of what your car might be worth.
        </p>
        <div class="os-form__section">
          <div class="os-form__subtitle">Your car information</div>
          <InputText
            v-model="registrationNumber"
            name="registration-number"
            rules="required|registration|uk-reg"
            placeholder="Car Registration"
            type="text"
            class="textInput--uppercase"
            validate
          />
          <InputText
            v-model="mileage"
            name="mileage"
            placeholder="Mileage"
            rules="required|mileage"
            inputmode="decimal"
            validate
            @focus="onMileageFocus"
            @blur="onMileageBlur"
          />
        </div>
        <OsButtonTray>
          <OsButton
            is-primary
            :is-disabled="invalid || loading || !canStartValuation"
            @click.prevent="startValuation()"
          >
            {{ `Next: ${loading ? 'Finding car...' : nextStepTitle}` }}
          </OsButton>
        </OsButtonTray>
      </div>
    </form>
  </section>
</template>

<script lang="ts">
import { useValueMyCarStore } from '~/stores/forms/valueMyCarStore'

export default {
  props: {
    nextStepTitle: {
      type: String,
      required: false,
      default: ''
    },
    invalid: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  emits: ['next'],

  setup(props, { emit }) {
    const valueMyCarStore = useValueMyCarStore()
    const mileage = ref<string>('0')
    const loading = ref<boolean>(false)
    const mileageTouched = ref<boolean>(false)
    const registrationNumber = ref<string>(
      valueMyCarStore.initialRegistration ?? ''
    )

    const mileageNumber = computed<number>(() =>
      parseInt(mileage.value.replaceAll(',', ''))
    )
    const canStartValuation = computed<boolean>(
      () => mileageNumber.value > 0 && registrationNumber.value !== ''
    )

    const startValuation = async () => {
      loading.value = true
      await valueMyCarStore.createValuation(
        registrationNumber.value.toUpperCase(),
        mileageNumber.value
      )
      loading.value = false
      emit('next')
    }

    const onMileageFocus = () => {
      mileage.value = mileage.value.replaceAll(',', '')
    }

    const onMileageBlur = () => {
      if (isNaN(mileageNumber.value)) {
        mileage.value = '0'
        return
      }

      mileage.value = `${new Intl.NumberFormat('en-GB').format(
        mileageNumber.value
      )}`
    }

    return {
      mileage,
      loading,
      mileageTouched,
      registrationNumber,
      mileageNumber,
      canStartValuation,
      startValuation,
      onMileageFocus,
      onMileageBlur
    }
  }
}
</script>

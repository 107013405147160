<template>
  <FormsContent
    :has-error="hasError"
    :current-step="currentStep"
    :form-type="formType"
    @start-again="resetForm"
  >
    <Form v-slot="{ meta }" as="section">
      <component
        :is="currentComponent"
        v-bind="{ invalid: !meta.valid, ...currentProps }"
        @next="next"
      >
        <template
          v-if="isStep(EEnquirySteps.ContactForm) && isAvailableForFinance"
        >
          <FormsSectionFinanceEligibilityBenefits />
        </template>
        <template
          v-if="isStep(EEnquirySteps.ContactForm)"
          #button-tray="{ valid }"
        >
          <OsButtonTray>
            <OsButton
              aria-label="Send"
              :is-disabled="isLoading || !valid"
              :is-primary="!isAvailableForFinance"
              @click.prevent="send"
            >
              Send
            </OsButton>
            <OsButton
              v-if="isAvailableForFinance"
              is-primary
              :is-disabled="isLoading || !valid"
              aria-label=" Send and Check finance eligibility"
              @click.prevent="sendAndCheckEligibility"
            >
              Send and Check finance eligibility
            </OsButton>
          </OsButtonTray>
        </template>
      </component>
    </Form>
  </FormsContent>
</template>

<script lang="ts">
import { EFormType } from '~/types/forms'
import { useFormsStore } from '~/stores/forms/formsStore'
import { EEnquirySteps, useEnquiryStore } from '~/stores/forms/enquiryStore'
import { useCustomerTypeStore } from '~/stores/customerTypeStore'
import { useOnlineCreditDecisionStore } from '~/stores/forms/ocd/onlineCreditDecisionStore'
import { useRetailerStore } from '~/stores/retailerStore'
import {
  FormsSectionVehicleDetails,
  FormsStepRetailer,
  FormsStepRetailerGroup,
  FormsStepCustomerDetails,
  FormsOcdCreditCheck,
  FormsOcdFinanceApplication,
  FormsEnquiryThankYou
} from '#components'

export default {
  setup() {
    const formsStore = useFormsStore()
    const enquiryStore = useEnquiryStore()
    const retailerStore = useRetailerStore()
    const ocdStore = useOnlineCreditDecisionStore()
    const { getCustomerTypeName } = useCustomerTypeStore()
    const { initTalkingTag } = useFlashTalking()

    const { $dataLayer } = useNuxtApp()
    const config = useRuntimeConfig()
    const router = useRouter()
    const route = useRoute()

    const formType = EFormType.Enquiry
    const { currentStep, nextStep, next } = enquiryStore.navigation
    const { isAvailableForFinance, checkFinanceEligibility } = useOcd()
    const { trackPageView } = useFormTracking(formType, currentStep)
    const { isBackDisabled } = useCreateFormContainer(formType)

    const vehicle = computed(() => formsStore.getVehicle)

    onMounted(() => {
      enquiryStore.resetWithNavigation()
      trackPageView()
      trackOptimizelyEvent(EOptimizelyEvent.EnquiryStart)
    })

    const isLoading = ref<boolean>(false)
    const componentDictionary = computed(() => ({
      [EEnquirySteps.Enquiry]: FormsSectionVehicleDetails,
      [EEnquirySteps.Showroom]: formsStore.isVehicleMBCUKRetailer
        ? FormsStepRetailer
        : FormsStepRetailerGroup,
      [EEnquirySteps.ContactForm]: FormsStepCustomerDetails,
      [EEnquirySteps.CheckFinanceEligibility]: FormsOcdCreditCheck,
      [EEnquirySteps.ApplyForFinance]: FormsOcdFinanceApplication,
      [EEnquirySteps.ThankYou]: FormsEnquiryThankYou
    }))

    const { hasError, mappedSteps, isStep, currentComponent } =
      useOsForm<EEnquirySteps>(enquiryStore, componentDictionary)

    const title = computed(() =>
      isStep(EEnquirySteps.ThankYou) && ocdStore.isOcdActive
        ? 'Finance application result'
        : currentStep.value.title
    )

    const currentProps = computed(() => {
      return {
        formType,
        stepTitle: title.value,
        nextStepTitle: nextStep.value?.label,
        showLiveChat: formsStore.isVehicleMBCUKRetailer,
        vehicle: formsStore.getVehicle,
        isSmart: formsStore.isVehicleSmart,
        isMBCUKRetailer: formsStore.isVehicleMBCUKRetailer,
        isNew: formsStore.isNewVehicle,
        hasAddress: false,
        isPhoneOptional: true,
        isLoading: isLoading.value,
        retailer: retailerStore.retailer,
        isCompletedFinanceApplication: ocdStore.isOcdActive
      }
    })

    const submit = async () => {
      if (!retailerStore.retailer) return

      const detailsUrl = getVehicleDetailsUrl(
        formsStore.getVehicle!,
        getCustomerTypeName,
        route,
        router,
        config.public.baseUrl
      )

      isLoading.value = true
      const response: any = await enquiryStore.submit(detailsUrl)
      // todo: move mixin to composition
      initTalkingTag('112997', 'Send_Enquiry')

      return response
    }

    const send = async () => {
      const response = await submit()
      $dataLayer.linkClick({
        pageCategory: 'enquiry: about you',
        category: 'enquiry form',
        action: 'send enquiry',
        vehicle: vehicleAnalytics(vehicle.value)
      })

      isLoading.value = false

      if (response?.data?.requestProcessorResponse?.statusCode === 200) {
        ocdStore.setIsEnquirySent()
        next(2)
      }

      trackOptimizelyEvent(EOptimizelyEvent.EnquiryComplete)
    }

    const sendAndCheckEligibility = async () => {
      await submit()
      await checkFinanceEligibility(false)
      await ocdStore.fetchOcdSession()
      $dataLayer.linkClick({
        pageCategory: 'enquiry: about you',
        category: 'enquiry form',
        action: 'send and check finance eligibility',
        vehicle: vehicleAnalytics(vehicle.value)
      })

      isLoading.value = false

      next()
    }

    watch(currentComponent, () => trackPageView())

    watchEffect(() => {
      isBackDisabled.value = isStep(EEnquirySteps.CheckFinanceEligibility)
    })

    const resetForm = async () => {
      ocdStore.resetOcdSession()
      enquiryStore.resetWithNavigation()

      await ocdStore.fetchOcdSession()
    }

    return {
      formType,
      mappedSteps,
      currentStep: computed(() => ({
        ...currentStep.value,
        title: title.value
      })),
      resetForm,
      currentComponent,
      send,
      currentProps,
      isAvailableForFinance,
      isLoading,
      checkFinanceEligibility,
      isStep,
      EEnquirySteps,
      hasError,
      next,
      sendAndCheckEligibility
    }
  }
}
</script>

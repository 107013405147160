<template>
  <div class="your-finance-quote">
    <FormsSectionVehicleDetails
      :show-submit-button="false"
      class="os-form__section"
      :vehicle="vehicle!"
    />
    <div class="os-form__section">
      <div class="os-form__subtitle">Your finance quote</div>
      <p
        v-for="text in yourFinanceQuoteText"
        :key="text"
        class="os-form__disclaimer"
      >
        {{ text }}
      </p>
    </div>
    <FinanceTable
      class="mt-6"
      :quote="financeQuotesStore.currentQuoteDto"
      :vehicle="vehicle!"
    />
    <OsButtonTray>
      <OsButton
        is-primary
        :is-loading="isLoading"
        :is-disabled="isLoading"
        @click.prevent="next"
      >
        {{ isLoading ? 'Please wait' : 'Next: Confirm your details' }}
      </OsButton>
    </OsButtonTray>
  </div>
</template>

<script lang="ts">
import { NewVehicle, UsedVehicle } from '~/types/vehicle'
import { useCreditCheckStore } from '~/stores/forms/ocd/creditCheckStore'
import { useOnlineCreditDecisionStore } from '~/stores/forms/ocd/onlineCreditDecisionStore'
import { useFinanceQuotesStore } from '~/stores/finance/quotesStore'

export default {
  name: 'OcdCreditCheckFinanceQuote',

  props: {
    vehicle: {
      type: Object as PropType<NewVehicle | UsedVehicle>,
      required: false,
      default: undefined
    }
  },

  emits: ['next'],

  setup(props, { emit }) {
    const financeQuotesStore = useFinanceQuotesStore()
    const ocdStore = useOnlineCreditDecisionStore()
    const creditCheckStore = useCreditCheckStore()

    const isLoading = computed(() => creditCheckStore.isCreditCheckLoading)

    const next = async () => {
      await ocdStore.fetchProposalReference()
      emit('next')
    }

    return {
      isLoading,
      next,
      financeQuotesStore
    }
  }
}
</script>

<style scoped lang="scss">
.your-finance-quote {
  &:deep(.financeTable__group:first-child) {
    border: none;
  }
}
</style>

<template>
  <p class="text text-sm text-gray-500 mb-4">{{ field.Label }}</p>
  <InputRadioGroup
    v-model="model"
    :items="field.Options"
    class="mb-6"
    item-value="Value"
    item-text="Text"
  >
    <template #default="{ item }">
      {{ item.Text }}
    </template>
  </InputRadioGroup>
</template>

<script lang="ts">
import { IOcdField } from '~/types/forms'

export default {
  name: 'OcdFormFactoryRadio',

  props: {
    field: {
      type: Object as PropType<IOcdField>,
      required: true
    }
  },

  setup(props) {
    const { updateFormValue } = useFormFactory()
    const field = ref<IOcdField>({ ...props.field })

    const model = computed({
      get: () => {
        return (
          field.value.Options.find(
            ({ Value }) => Value === field.value.Value
          ) || field.value.Options[1]
        )
      },
      set: (value: any) => {
        field.value.Value = value?.Value || 'False'
        updateFormValue({ field: props.field, value: value?.Value || 'False' })
      }
    })

    const defaultValue = computed<any>(() => {
      return (
        field.value.Options.find(({ Value }) => Value === field.value.Value) ||
        field.value.Options[1].Value
      )
    })

    // Set default value
    updateFormValue({
      field: props.field,
      value: defaultValue.value.Value || 'False'
    })
    return { model }
  }
}
</script>

<template>
  <div class="login-prompt">
    <FormsSectionLoginCard v-if="!isLoggedIn" :form-type="formType" />
    <OsButtonTray>
      <OsButton v-if="isLoggedIn" :is-primary="true" @click.prevent="next">
        {{ `Next: ${nextStepTitle}` }}
      </OsButton>
      <OsButton v-else-if="!loginRequired" @click.prevent="onContinueAsGuest">
        Continue as guest
      </OsButton>
      <OsLoginButton
        v-if="!isLoggedIn"
        data-locator="login"
        :tracking-options="{
          category: `form-tray-${formType}`
        }"
        @click="onLogin"
      />
    </OsButtonTray>
  </div>
</template>

<script lang="ts">
import { EFormType } from '~/types/forms'

import { NewVehicle, UsedVehicle } from '~/types/vehicle'

export default {
  name: 'FormsStepLogin',

  props: {
    nextStepTitle: {
      type: String,
      required: true
    },
    formType: {
      type: String as PropType<EFormType>,
      required: true
    },
    vehicle: {
      type: Object as PropType<NewVehicle | UsedVehicle>,
      required: false,
      default: undefined
    },
    loginRequired: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  emits: ['next'],

  setup(props, { emit }) {
    const { $dataLayer } = useNuxtApp()

    const { isLoggedIn } = useProfile()
    const { onLogin } = useCiamLogin()

    const next = () => {
      if (props.loginRequired && !isLoggedIn.value) return

      emit('next')
    }

    const onContinueAsGuest = () => {
      $dataLayer.linkClick({
        category: `form-tray-${props.formType}`,
        action: 'guest'
      })

      next()
    }

    watch(
      isLoggedIn,
      (val: boolean) => {
        if (val) next()
      },
      { immediate: true }
    )

    return {
      onLogin,
      isLoggedIn,
      next,
      onContinueAsGuest
    }
  }
}
</script>

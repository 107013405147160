<template>
  <div class="os-date-input flex flex-col fit">
    <p class="mb-1 mt-2"><span class="text-red-600 mr-1">*</span>{{ label }}</p>
    <div class="flex flex-wrap">
      <div class="m-1 ml-0 w-24">
        <InputText
          v-model="day"
          name="dd"
          :max="12"
          :min="1"
          placeholder="DD"
          :pattern="'[0-9\]+'"
          rules="day"
          type="text"
          inputmode="numeric"
          maxlength="2"
          :validate="true"
          :show-errors="false"
          @update:model-value="onInput($event, 'mm')"
          @blur="onBlur"
        />
      </div>
      <div class="m-1 w-24">
        <InputText
          v-model="month"
          name="mm"
          :max="12"
          :min="1"
          placeholder="MM"
          :pattern="'[0-9\]+'"
          rules="month"
          type="text"
          inputmode="numeric"
          maxlength="2"
          :validate="true"
          :show-errors="false"
          @update:model-value="onInput($event, 'yyyy')"
          @blur="onBlur"
        />
      </div>
      <div class="ml-0 mt-1 sm:m-1 w-36">
        <InputText
          v-model="year"
          name="yyyy"
          :max="2025"
          :min="1900"
          placeholder="YYYY"
          rules="year"
          type="text"
          inputmode="numeric"
          :pattern="'[0-9\]+'"
          maxlength="4"
          :validate="true"
          :show-errors="false"
          @update:model-value="onInput($event)"
        />
      </div>
    </div>
    <OsError v-if="errors.length">
      {{ errors[0] }}
    </OsError>
  </div>
</template>

<script lang="ts">
export default {
  name: 'InputDate',

  props: {
    modelValue: {
      type: String,
      required: false,
      default: () => ''
    },

    label: {
      type: String,
      required: true
    },

    errors: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => []
    }
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const day = ref<string>('')
    const month = ref<string>('')
    const year = ref<string>('')

    const onInput = (value: string, nextEl?: string) => {
      const n = String(value)

      if (n.length === 2 && !!nextEl) {
        const el = document.getElementById(nextEl)

        if (el) el.focus()
      }

      // Emit the full date
      emit('update:modelValue', `${day.value}/${month.value}/${year.value}`)
    }

    // Watch for changes in the parent
    watch(
      () => props.modelValue,
      (newValue) => {
        ;[day.value, month.value, year.value] = newValue.split('/')
      }
    )

    // Add a leading zero to the day or month if it's less than 10
    const onBlur = ($event: any) => {
      const n = parseInt($event.target.value)

      if (n < 10) {
        $event.target.value = `0${n}`
        $event.target.dispatchEvent(new Event('input'))
      }
    }

    return {
      day,
      month,
      year,
      onBlur,
      onInput
    }
  }
}
</script>

<template>
  <div v-if="section.Label || actions.length">
    <div class="form-title">
      <span v-if="section.Label">{{ section.Label }}</span>
      <div class="form_action">
        <a
          v-for="(removeAction, index) in removeActions"
          :key="`remove_${index}`"
          class="form_action_remove os-form__link"
          @click.prevent="removeSection(removeAction)"
        >
          {{ removeAction.Description }}
        </a>
        <a
          v-for="(addAction, index) in addActions"
          :key="`add_${index}`"
          class="form_action_add os-form__link"
          @click.prevent="addSection()"
        >
          {{ addAction.Description }}
        </a>
      </div>
    </div>
    <template v-if="actionsErrors.length">
      <OsError
        v-for="(error, index) in actionsErrors"
        :key="`${error}_${index}`"
      >
        {{ error }}
      </OsError>
    </template>
    <p
      v-for="(textAction, index) in textActions"
      :key="`text_${index}`"
      class="form_action_text"
    >
      {{ textAction.Description }}
    </p>
  </div>
</template>

<script lang="ts">
import { useFormFactoryStore } from '~/stores/forms/ocd/formFactoryStore'
import { EOcdActionType, IOcdAction, IOcdSection } from '~/types/forms'

export default {
  name: 'OcdFormFactorySectionTitle',
  props: {
    section: {
      type: Object as PropType<IOcdSection>,
      required: true
    }
  },
  setup(props) {
    const ocdFormFactoryStore = useFormFactoryStore()

    const actions = computed(() => props.section.Actions?.Header || [])

    const removeActions = computed<IOcdAction[]>(() =>
      actions.value.filter((a) => a.Type === EOcdActionType.RemoveSection)
    )
    const addActions = computed<IOcdAction[]>(() =>
      actions.value.filter((a) => a.Type === EOcdActionType.AddSection)
    )
    const textActions = computed<IOcdAction[]>(() =>
      actions.value.filter((a) => a.Type === EOcdActionType.Text)
    )

    const actionsErrors = computed(() =>
      removeActions.value
        .concat(addActions.value)
        .reduce((prev: string[], current) => {
          return [...prev, ...(current.Error ? [current.Error] : [])]
        }, [])
    )

    const removeSection = (action: IOcdAction) => {
      ocdFormFactoryStore.removeSection(action.Value)
    }

    const addSection = () => {
      // todo
    }

    return {
      actions,
      removeActions,
      addActions,
      textActions,
      removeSection,
      addSection,
      actionsErrors
    }
  }
}
</script>

<style lang="scss" scoped>
.form_action_text {
  font-family: $mb-font-text;
  font-size: rem(14);
  color: $grey--darkest;
  margin-bottom: rem(16);
}
</style>

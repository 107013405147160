<template>
  <div class="logo">
    <div class="logo-animated" v-bind="$attrs">
      <div
        :class="{
          'logo-animated__image': isMounted,
          'logo-animated__image--playing': isLoading && isMounted
        }"
      ></div>
    </div>
  </div>
</template>

<script>
import { useVehiclesSearchStore } from '~/stores/vehicles/searchStore'
import { useVehicleStore } from '~/stores/vehicles/detailsStore'

export default {
  name: 'LogoAnimated',
  props: {
    text: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup() {
    const isMounted = ref(false)
    const searchStore = useVehiclesSearchStore()
    const detailsStore = useVehicleStore()

    const isLoading = computed(
      () => searchStore.isLoading || detailsStore.isLoading
    )

    onMounted(() => {
      isMounted.value = true
    })

    return {
      isLoading,
      isMounted
    }
  }
}
</script>

<style lang="scss" scoped>
.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px !important;
  height: 32px !important;
  overflow: hidden;
}

$size: 100px;

.logo-animated {
  flex: none;
  position: relative;
  width: $size !important;
  height: $size !important;
  overflow: hidden;
  transform-origin: center;
  transform: scale(0.5);
  cursor: pointer;

  .logo-animated__image {
    width: 6400px;
    height: $size;
    background-image: url('~/assets/images/ui/logo-animation-6400x100-A.webp');
    transform: translateX(-6300px);

    &--playing {
      cursor: auto;
      animation: logoAnimation 2.8s steps(63) infinite;
      animation-fill-mode: forwards;
    }
  }
}

@keyframes logoAnimation {
  0% {
    transform: translateX(0);
  }
  51% {
    transform: translateX(-6300px);
  }
  100% {
    transform: translateX(0);
  }
}
</style>

<template>
  <div class="os-form__section">
    <div class="os-form__disclaimer" v-html="field.Value"></div>
  </div>
</template>

<script lang="ts">
import { IOcdField } from '~/types/forms/ocdForm'

export default {
  name: 'FinanceResultDisplay',
  props: {
    field: {
      type: Object as PropType<IOcdField>,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.os-form__disclaimer {
  margin-top: rem(24);
  &:deep(p:has(strong > u)) {
    &:first-of-type {
      display: none;
    }
  }
  &:deep(a) {
    font-family: $mb-font-text-bold;
    text-decoration: none;
    color: $blue--dark;
  }
  &:deep(p) {
    font-size: rem(14);
    color: $grey--darkest;
    margin-bottom: rem(16);
  }
}
</style>

<template>
  <div class="finance-result-display os-form__section">
    <div class="os-form__subtitle">
      {{ subTitle }}
    </div>
    <div v-if="heading && resultText" class="finance-result-display__result">
      <h6 class="mb-6">{{ heading }}</h6>
      <p
        v-for="(item, index) in resultText"
        :key="index"
        class="os-form__disclaimer"
      >
        <span v-html="item"></span>
      </p>
    </div>
    <div v-else class="finance-result-display__waiting">
      <OsIcon name="Loader" size="2.5rem" />
      <p class="text bold" v-html="waitingText" />
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'FinanceResultDisplay',
  props: {
    subTitle: {
      type: String,
      required: false,
      default: undefined
    },
    heading: {
      type: String,
      required: false,
      default: undefined
    },
    resultText: {
      type: Array,
      required: false,
      default: undefined
    },
    waitingText: {
      type: String,
      required: false,
      default: undefined
    }
  }
}
</script>

<style lang="scss" scoped>
.finance-result-display__waiting {
  display: flex;
  gap: rem(16);

  #loader {
    position: relative;
    top: -2px;
  }
}

.finance-result-display__result {
  border: 1px solid $grey--light;
  border-radius: 4px;
  padding: rem(16);

  p:not(:last-child) {
    margin-bottom: rem(16);
  }
}
</style>

<template>
  <div class="os-form__section">
    <div class="os-form__section">
      <p class="os-form__text-content">
        Your settlement figure is the amount still owed for your car if you want
        to pay off your finance agreement early, including any fees.<br /><br />
        Mercedes-Benz Finance customers can view their settlement quote in
        <a
          class="os-form__link"
          target="_blank"
          href="https://customerportal.mercedes-benz.com/cp/portal/login.html"
        >
          Mercedes me finance
        </a>
        or in your monthly email statement. It can also be requested via our
        <a
          class="os-form__link"
          target="_blank"
          href="https://help.mercedes-benz.co.uk/app/answers/detail/a_id/115?"
        >
          online form
        </a>
        or by calling
        <a class="os-form__link" href="tel:03708470700">0370 847 0700</a>
        (you’ll need your 16 digit agreement number and payment details
        available).
      </p>
      <div class="os-form__section">
        <InputText
          v-model="settlement"
          name="settlement-input"
          class="os-form__settlement-input"
          rules="min_value:0|decimal"
          inputmode="decimal"
          placeholder="Enter settlement figure"
          validate
          has-currency
        />
      </div>
    </div>
    <OsButtonTray>
      <OsButton
        is-primary
        :is-disabled="!canProceed"
        @click.prevent="$emit('next')"
      >
        {{ `Next: ${nextStepTitle}` }}
      </OsButton>
    </OsButtonTray>
  </div>
</template>

<script lang="ts">
import { useValueMyCarStore } from '~/stores/forms/valueMyCarStore'

export default {
  name: 'SettlementFigure',
  props: {
    nextStepTitle: {
      type: String,
      required: true,
      default: ''
    }
  },

  emits: ['next'],

  setup() {
    const valueMyCarStore = useValueMyCarStore()
    const settlement = ref('0.00')
    const canProceed = computed(() => meta.valid)

    const { meta } = useField('settlement-input')

    watch(
      settlement,
      () => {
        if (meta.valid) {
          valueMyCarStore.updateSettlementFigure(settlement.value)
        }
      },
      { immediate: true }
    )

    return {
      settlement,
      canProceed
    }
  }
}
</script>

<style lang="scss" scoped>
.os-form__settlement-input {
  max-width: rem(209); // requested by design
}
</style>

<template>
  <div class="thank-you">
    <h2 class="os-form__title os-form__section">
      {{
        completedFinanceApplication
          ? 'Finance application result'
          : 'We have received your enquiry'
      }}
    </h2>

    <FormsOcdFinanceResult
      v-if="completedFinanceApplication"
      sub-title="Your finance application result"
      :heading="financeResult.heading"
      :result-text="financeResult.text"
      :waiting-text="financeWaitingText('financeApplication').waiting"
    />

    <!-- Show the full page only when we have a financeResult -->
    <template v-if="!completedFinanceApplication || financeResult.heading">
      <div class="os-form__section">
        <p v-if="!completedFinanceApplication">
          Your preferred Showroom will be in touch shortly.
        </p>

        <div class="thank-you__carSpecs">
          <div class="os-form__section">
            <div class="os-form__subtitle">Your {{ vehicle.Model }}</div>
            <FormsSectionVehicleSpecs :vehicle="vehicle" />
          </div>
          <div class="os-form__section">
            <OsPanel>
              <VehiclePricing :vehicle="vehicle" />
            </OsPanel>
          </div>
          <div v-if="hasOffers" class="os-form__section">
            <VehicleOffers
              :finance-offers="financeOffers"
              :non-monetary-offers="nonMonetaryOffers"
              :monetary-offers="monetaryOffers"
            />
          </div>
        </div>
      </div>

      <SurveyMobile
        :form-type="formType"
        :vehicle="vehicle"
        :used-in-form="true"
        immediate
      />

      <div v-if="financeQuote" class="os-form__section">
        <div class="os-form__subtitle">Your finance quote</div>
        <FinanceTable :quote="financeQuote" :vehicle="vehicle" />
      </div>

      <div v-if="isShowFormButtons" class="os-form__section">
        <div class="os-form__subtitle">Your next steps</div>
        <FormsCtaDirectoryContent
          :directory-buttons="directoryButtons"
          :show-finance="false"
          :tracking-options="{ pageCategory: 'ocd:thank you' }"
        />
      </div>

      <div v-else class="os-form__section">
        <div class="os-form__subtitle">Your Showroom information</div>
        <RetailerInformation :retailer="retailer!" :vehicle="vehicle" />
      </div>
    </template>
  </div>
</template>
<script lang="ts">
import { NewVehicle, UsedVehicle } from '~/types/vehicle'
import { ITier } from '~/types/onlineCreditDecision'
import { EFormType } from '~/types/forms'
import { ERoutes } from '~/types/routes'
import surveyQuestions from '~/constants/surveyQuestions'

import { useFinanceQuotesStore } from '~/stores/finance/quotesStore'
import { useRetailerStore } from '~/stores/retailerStore'
import { useFormsStore } from '~/stores/forms/formsStore'
import { useOnlineCreditDecisionStore } from '~/stores/forms/ocd/onlineCreditDecisionStore'

export default defineComponent({
  name: 'FormsFinanceEligibilityThankYou',

  setup() {
    const route = useRoute()
    const ocdStore = useOnlineCreditDecisionStore()
    const formsStore = useFormsStore()
    const retailerStore = useRetailerStore()
    const financeQuotesStore = useFinanceQuotesStore()
    const { trackPageView } = useFormTracking(EFormType.FinanceEligibility)
    const formType = EFormType.FinanceEligibility

    const completedFinanceApplication = computed<boolean>(
      () => ocdStore.isOcdActive
    )
    const financeResult = computed<ITier>(() => ocdStore.financeResult)
    const retailer = computed(() => retailerStore.retailer)
    const vehicle = computed<NewVehicle | UsedVehicle>(
      () => formsStore.getVehicle!
    )
    const financeQuote = computed(() => financeQuotesStore.currentQuoteDto)

    const { hasOffers, financeOffers, monetaryOffers, nonMonetaryOffers } =
      useVehicleOffers(vehicle)

    const isShowFormButtons = computed<boolean>(() => {
      if (route.name === ERoutes.Offer) return false

      const isSuccessResult =
        financeResult.value?.heading?.indexOf('Congratulations') > -1
      return isSuccessResult && completedFinanceApplication.value
    })

    const directoryButtons = directoryOptions([
      EFormType.Offer,
      EFormType.StockNotification,
      EFormType.TestDrive,
      EFormType.Enquiry
    ]).map((formOption) => {
      if (formOption.formName === EFormType.Enquiry) {
        return {
          ...formOption,
          title: `${formOption.title} <span>(Completed)</span>`,
          disabled: true
        }
      }

      return formOption
    })

    watch(
      () => financeResult.value?.heading,
      (val) => {
        if (val) {
          trackPageView({
            pageCategory: `ocd:application results`,
            ocdApplicationResults: val
          })
        }
      }
    )

    return {
      isShowFormButtons,
      completedFinanceApplication,
      retailer,
      vehicle,
      financeResult,
      hasOffers,
      financeOffers,
      monetaryOffers,
      nonMonetaryOffers,
      financeQuote,
      directoryButtons,
      formType,
      surveyQuestions
    }
  }
})
</script>

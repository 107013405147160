<template>
  <FormsContent
    :steps="mappedSteps"
    :has-error="hasError"
    :current-step="currentStep"
    :form-error-bag="formErrorBag"
    :form-type="formType"
    @start-again="resetForm"
  >
    <Form v-slot="{ meta }" as="section">
      <div class="os-form__section">
        <component
          :is="currentComponent"
          v-bind="{ invalid: !meta.valid, ...currentProps }"
          @next="next"
          @value-other-car="valueOtherCar"
          @add-to-finance="addToFinance"
        />
      </div>
    </Form>
  </FormsContent>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia'
import { ERoutes } from '~/types/routes'
import { EFormType } from '~/types/forms'
import {
  EValueMyCarSteps,
  useValueMyCarStore
} from '~/stores/forms/valueMyCarStore'
import { useVehiclesSearchStore } from '~/stores/vehicles/searchStore'

import {
  FormsValueMyCarRegistrationSearch,
  FormsValueMyCarCondition,
  FormsValueMyCarSettlementFigure,
  FormsValueMyCarRetailer,
  FormsStepCustomerDetails,
  FormsValueMyCarValuation
} from '#components'
import { useRetailerStore } from '~/stores/retailerStore'
import { useVehicleStore } from '~/stores/vehicles/detailsStore'
import { useFinanceQuotesStore } from '~/stores/finance/quotesStore'

export default {
  name: 'ValueMyCar',
  setup() {
    const route = useRoute()

    const valueMyCarStore = useValueMyCarStore()
    const vehiclesSearchStore = useVehiclesSearchStore()
    const { hideSidepanel } = useSidepanel(ESidepanel.FORMS)
    const { $dataLayer } = useNuxtApp()

    const formType = EFormType.ValueMyCar
    const {
      currentStep,
      nextStep,
      next: navigationNext
    } = valueMyCarStore.navigation
    const { trackPageView: formTrackPage } = useFormTracking(
      formType,
      currentStep
    )
    useCreateFormContainer(formType)

    const { getErrorMessage: errorMessage, chosenCondition } =
      storeToRefs(valueMyCarStore)

    onMounted(() => {
      valueMyCarStore.resetWithNavigation()
      trackPageView()
      trackOptimizelyEvent(EOptimizelyEvent.ValueMyCarStart)
    })

    const loadingValuation = ref<boolean>(false)

    const componentDictionary = computed(() => ({
      [EValueMyCarSteps.RegistrationSearch]: FormsValueMyCarRegistrationSearch,
      [EValueMyCarSteps.Condition]: FormsValueMyCarCondition,
      [EValueMyCarSteps.SettlementFigure]: FormsValueMyCarSettlementFigure,
      [EValueMyCarSteps.Retailer]: FormsValueMyCarRetailer,
      [EValueMyCarSteps.AboutYou]: FormsStepCustomerDetails,
      [EValueMyCarSteps.Valuation]: FormsValueMyCarValuation
    }))

    const { hasError, mappedSteps, isStep, currentComponent } =
      useOsForm<EValueMyCarSteps>(valueMyCarStore, componentDictionary)

    const currentProps = computed(() => ({
      stepTitle: currentStep.value.title,
      formType,
      nextStepTitle: loadingValuation.value
        ? 'Retrieving valuation...'
        : nextStep.value?.title,
      isLoading: loadingValuation.value
    }))

    const next = async (skipIndexIncrement?: boolean) => {
      if (isStep(EValueMyCarSteps.AboutYou)) {
        loadingValuation.value = true
        await valueMyCarStore.addCustomerToCurrentValuation()
        await valueMyCarStore.fetchFinalizedValuation()
        loadingValuation.value = false
        trackOptimizelyEvent(EOptimizelyEvent.ValueMyCarComplete)
      }

      if (!skipIndexIncrement) {
        navigationNext()
      }

      trackPageView()
    }

    const trackingVehicle = computed(() => ({
      condition: chosenCondition.value?.conditionAnswer ?? ''
    }))

    const valueOtherCar = () => {
      $dataLayer.linkClick({
        pageCategory: 'value my car:value another car',
        category: 'vmc valuation page',
        vehicle: trackingVehicle.value,
        action: 'value another car'
      })

      valueMyCarStore.resetWithNavigation()
      trackPageView()
    }

    const vehiclesDetailsStore = useVehicleStore()
    const retailerStore = useRetailerStore()

    const addToFinance = () => {
      if (route.name === ERoutes.Results) {
        vehiclesSearchStore.fetch()
      }

      if (route.name === ERoutes.Vehicle) {
        const financeQuotesStore = useFinanceQuotesStore()

        // If we on details page.
        const vin = vehiclesDetailsStore.vehicle!.Vin
        const gssnId = retailerStore.retailer!.GssnId!
        const vehicleType = vehiclesDetailsStore.vehicle?.VehicleType

        financeQuotesStore.fetchQuotes(vin, gssnId, vehicleType)
      }

      $dataLayer.linkClick({
        pageCategory: 'value my car:add to finance',
        vehicle: trackingVehicle.value,
        action: 'add to finance',
        category: 'vmc valuation page'
      })

      hideSidepanel()
      valueMyCarStore.resetWithNavigation()
    }

    const trackPageView = () => {
      formTrackPage({
        vehicle: trackingVehicle.value,
        category: 'vmc valuation page'
      })
    }

    const formErrorBag = ref<FormErrorBag>(new FormErrorBag())

    watchEffect(() => {
      if (hasError.value && errorMessage.value) {
        formErrorBag.value.record([errorMessage.value])
        formErrorBag.value.setShowDefaultError(false)
      } else {
        formErrorBag.value.reset()
      }
    })

    return {
      formType,
      mappedSteps,
      hasError,
      resetForm: valueMyCarStore.resetWithNavigation,
      currentStep,
      currentComponent,
      currentProps,
      next,
      valueOtherCar,
      addToFinance,
      formErrorBag
    }
  }
}
</script>

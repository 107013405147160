import { defineStore } from 'pinia'
import { useCustomerTypeStore } from '../customerTypeStore'
import { useVehiclesSearchStore } from '../vehicles/searchStore'
import { useFiltersStore } from '../filtersStore'
import { useFilterDataStore } from '../filterDataStore'
import { useFormsStore } from '~/stores/forms/formsStore'
import { IStep } from '~/types/steps'
import { EStoreStatus } from '~/types/forms'

export enum ECallbackSteps {
  Login = 'Login',
  StockNotification = 'Stock notification',
  ContactForm = 'About you',
  ThankYou = 'Thank you'
}

export const steps: IStep[] = [
  {
    label: ECallbackSteps.Login,
    title: 'Login'
  },
  {
    label: ECallbackSteps.StockNotification,
    title: 'Request a stock notification'
  },
  {
    label: ECallbackSteps.ContactForm,
    title: 'Tell us about you'
  },
  {
    label: ECallbackSteps.ThankYou,
    title: 'We have received your request'
  }
]

interface ICallbackStoreState {
  status: EStoreStatus
}

export const useCallbackStore = defineStore('callback', {
  hasNavigation: true,

  state: (): ICallbackStoreState => {
    return {
      status: EStoreStatus.OK
    }
  },
  getters: {
    hasError(state: ICallbackStoreState) {
      return state.status === EStoreStatus.ERROR
    },
    getSteps(): IStep[] {
      return steps
    },
    /**
     * If the first model selected is a smart model we will return true
     * otherwise it will be false and deefault to MB
     */
    isSmart(): boolean {
      const filtersStore = useFiltersStore()
      const filterDataStore = useFilterDataStore()

      const { models } = filtersStore.active

      const smartModelIds = filterDataStore.getSmartModelIds

      return smartModelIds?.includes(models[0])
    }
  },
  actions: {
    reset(): void {
      this.$reset()
    },
    async submit(): Promise<any> {
      const { retailer } = useRetailerStore()

      if (!retailer) {
        this.status = EStoreStatus.ERROR
        return
      }
      const response = await this.api.forms.backorderSubmit(this.getPayload())

      if (response.data?.requestProcessorResponse?.statusCode !== 200) {
        this.status = EStoreStatus.ERROR
        return
      }

      return response
    },
    /**
     * Returns the final submission payload.
     */
    getPayload(): any {
      const { isSubmittingAsBusiness } = useFormsStore()
      const { isMotability } = useCustomerTypeStore()
      const { getCriteria, isNew, active } = useFiltersStore()
      const { bestMatchVehicles } = useVehiclesSearchStore()
      const filterDataStore = useFilterDataStore()
      const route = useRoute()
      const config = useRuntimeConfig()

      const filterData = isNew
        ? filterDataStore.getFilterDataNew
        : filterDataStore.getFilterDataUsed
      const smartModelIds = filterDataStore.getSmartModelIds

      const activeFilters = getCriteria
      const selectedFilters = getCriteria

      const isSmart: boolean = smartModelIds?.includes(active.models[0])
      const codeOpts = {
        isNew,
        isSmart,
        isMotability,
        isBusiness: isSubmittingAsBusiness
      }
      const CommunicationCode = getCallbackCommCodes(codeOpts)

      return {
        ...getFormPayload(route, isSmart),
        ...getValuationInfo(),
        Interest: {
          ProductRange:
            !activeFilters.ModelId || activeFilters.ModelId?.length > 1
              ? '80A'
              : bestMatchVehicles[0]?.ProductRange || ''
        },
        EnquiryInfo: {
          ...getEnquiryInfoCommons(config, isSmart),
          CommunicationCode,
          RequestSource: getCallbackRequestResource(codeOpts),
          TagURL: getTagUrl(CommunicationCode),
          FormID: isNew ? 'NCL' : 'UCL'
        },
        Criteria: {
          ...callbackSearchCriteria(selectedFilters, filterData),
          LimitToMotability: isMotability
        }
      }
    }
  }
})

<template>
  <ul
    class="hidden lg:flex min-[1400px]:-ml-9 text-lg leading-6 z-30"
    v-bind="$attrs"
  >
    <li
      v-if="!isRetailerMode || isNew"
      class="flex items-center px-5 border-b-2 border-transparent cursor-pointer"
      :class="{ 'border-b-blue-600': isActive('new') }"
      @click.prevent
      @mouseover="open('new')"
      @mouseleave="close()"
    >
      <NuxtLink
        to="/new"
        aria-label="Find New Cars"
        role="link"
        tabindex="0"
        @keydown.space.prevent.stop="open('new')"
        @keydown.enter.prevent.stop="open('new')"
        @click="close(), analytics('Find New Cars')"
      >
        Find New Cars
      </NuxtLink>
    </li>
    <li
      v-if="!isRetailerMode || isUsed"
      class="flex items-center px-5 border-b-2 border-transparent cursor-pointer"
      :class="{ 'border-b-blue-600': isActive('used') }"
      @click.prevent
      @mouseover="open('used')"
      @mouseleave="close()"
    >
      <NuxtLink
        to="/used"
        aria-label="Find Approved Used"
        role="link"
        tabindex="0"
        @keydown.space.prevent.stop="open('used')"
        @keydown.enter.prevent.stop="open('used')"
        @click="close(), analytics('Find Approved Used')"
      >
        Find Approved Used
      </NuxtLink>
    </li>

    <li
      v-if="valueMyCarEnabled"
      class="flex items-center px-5 border-b-2 border-transparent"
    >
      <button @click="close(), analytics('value my car'), showVMC()">
        Value My Car
      </button>
    </li>

    <li class="flex items-center px-5 border-b-2 border-transparent">
      <NuxtLink
        to="/page/faq"
        target="_blank"
        @click="close(), analytics('FAQ')"
      >
        <span class="hidden xl:inline">Frequently Asked Questions</span>
        <span class="inline xl:hidden">FAQ</span>
      </NuxtLink>
    </li>
  </ul>

  <transition
    enter-from-class="opacity-0 -translate-y-10"
    enter-active-class="transition duration-200 pointer-events-none"
    leave-to-class="opacity-0 -translate-y-10"
    leave-active-class="transition duration-200 pointer-events-none"
  >
    <aside
      v-if="isOpen"
      ref="target"
      class="hidden md:flex w-full bg-gray-950 absolute top-14 left-0 py-10 px-4 rounded-b-2xl z-20"
      @mouseenter="open()"
      @mouseleave="close()"
    >
      <div class="container mx-auto max-w-7xl flex" @mouseover.stop>
        <transition
          :enter-from-class="
            isActive('new')
              ? 'translate-x-10 opacity-0'
              : '-translate-x-10 opacity-0'
          "
          enter-active-class="transition duration-200"
          :leave-to-class="
            isActive('new')
              ? '-translate-x-10 opacity-0'
              : 'translate-x-10 opacity-0'
          "
          leave-active-class="transition duration-200"
          mode="out-in"
        >
          <div v-if="isActive('new')" class="flex gap-4">
            <div class="min-w-52">
              <p class="text-lg text-gray-400 font-thin">Find New cars</p>
              <ul class="mt-4 space-y-2">
                <li>
                  <NuxtLink
                    to="/new/model/eq-range"
                    class="text-white font-bold hover:text-gray-300"
                    tabindex="0"
                    @click="analytics('find: Electric Cars', 'new'), close()"
                  >
                    Electric cars
                  </NuxtLink>
                </li>
                <li>
                  <NuxtLink
                    to="/new/model/suv-range"
                    class="text-white font-bold hover:text-gray-300"
                    @click="analytics('find: The SUV Range', 'new'), close()"
                  >
                    The SUV range
                  </NuxtLink>
                </li>
                <li>
                  <NuxtLink
                    to="/new/models"
                    class="text-white font-bold hover:text-gray-300"
                    @click="
                      analytics('find: See the full range', 'new'), close()
                    "
                  >
                    See the full range
                  </NuxtLink>
                </li>
              </ul>
            </div>
            <div class="min-w-52">
              <p class="text-lg text-gray-400 font-thin">We recommend</p>
              <ul class="mt-4 space-y-2">
                <li>
                  <NuxtLink
                    to="/new/model/cle"
                    class="text-white hover:text-gray-300"
                    @click="
                      analytics('recommend: The all new CLE range', 'new'),
                        close()
                    "
                  >
                    The all new CLE range
                  </NuxtLink>
                </li>
                <li>
                  <NuxtLink
                    to="/new/model/phev-range"
                    class="text-white hover:text-gray-300"
                    @click="
                      analytics('recommend: The PHEV range', 'new'), close()
                    "
                  >
                    The PHEV range
                  </NuxtLink>
                </li>
                <li>
                  <NuxtLink
                    to="/new/model/a-class"
                    class="text-white hover:text-gray-300"
                    @click="
                      analytics('recommend: The A-Class range', 'new'), close()
                    "
                  >
                    The A-Class range
                  </NuxtLink>
                </li>
              </ul>
            </div>
            <div class="min-w-52">
              <p class="text-lg text-gray-400 font-thin">Icons</p>
              <ul class="mt-4 space-y-2">
                <li>
                  <NuxtLink
                    to="/new/model/amg-range"
                    class="text-white hover:text-gray-300"
                    @click="analytics('icons: Mercedes-AMG', 'new'), close()"
                  >
                    Mercedes-AMG
                  </NuxtLink>
                </li>
                <li>
                  <NuxtLink
                    to="/new/model/g-class"
                    class="text-white hover:text-gray-300"
                    @click="analytics('icons: G-Class', 'new'), close()"
                  >
                    G-Class
                  </NuxtLink>
                </li>
              </ul>
            </div>
          </div>

          <div v-else class="flex gap-4">
            <div class="min-w-52">
              <p class="text-lg text-gray-400 font-thin">
                Find Approved Used cars
              </p>
              <ul class="mt-4 space-y-2">
                <li>
                  <NuxtLink
                    to="/used/model/eq-range"
                    class="text-white font-bold hover:text-gray-300"
                    @click="analytics('find: Electric cars', 'used'), close()"
                  >
                    Electric cars
                  </NuxtLink>
                </li>
                <li>
                  <NuxtLink
                    to="/used/model/a-class"
                    class="text-white font-bold hover:text-gray-300"
                    @click="
                      analytics('find: The A-Class range', 'used'), close()
                    "
                  >
                    The A-Class range
                  </NuxtLink>
                </li>
                <li>
                  <NuxtLink
                    to="/used/models"
                    class="text-white font-bold hover:text-gray-300"
                    @click="
                      analytics('find: Browse all used cars', 'used'), close()
                    "
                  >
                    See the full range
                  </NuxtLink>
                </li>
              </ul>
            </div>
            <div class="min-w-52">
              <p class="text-lg text-gray-400 font-thin">We recommend</p>
              <ul class="mt-4 space-y-2">
                <li>
                  <NuxtLink
                    to="/used/model/amg-range"
                    class="text-white hover:text-gray-300"
                    @click="
                      analytics('recommend: Mercedes-AMG', 'used'), close()
                    "
                  >
                    Mercedes-AMG
                  </NuxtLink>
                </li>
                <li>
                  <NuxtLink
                    to="/used/model/phev-range"
                    class="text-white hover:text-gray-300"
                    @click="
                      analytics('recommend: The PHEV range', 'used'), close()
                    "
                  >
                    The PHEV range
                  </NuxtLink>
                </li>
                <li>
                  <NuxtLink
                    to="/used/model/suv-range"
                    class="text-white hover:text-gray-300"
                    @click="
                      analytics('recommend: The SUV range', 'used'), close()
                    "
                  >
                    The SUV range
                  </NuxtLink>
                </li>
              </ul>
            </div>
          </div>
        </transition>

        <HeaderValueMyCar class="ml-auto" :condition="condition" />
      </div>
    </aside>
  </transition>
</template>

<script lang="ts">
import type { LocationQueryRaw } from 'vue-router'
import type { IBaseCriteria } from '~/types/filters'
import { ERoutes, type ResultsRoute } from '~/types/routes'
import { useFiltersStore } from '~/stores/filtersStore'
import { useCustomerTypeStore } from '~/stores/customerTypeStore'
import { useFilterDataStore } from '~/stores/filterDataStore'
import { useFormsStore } from '~/stores/forms/formsStore'
import { ECondition } from '~/types/vehicle'
import { EFormType } from '~/types/forms'

export default defineComponent({
  setup() {
    const filtersStore = useFiltersStore()
    const customerTypeStore = useCustomerTypeStore()
    const filterDataStore = useFilterDataStore()
    const { $dataLayer } = useNuxtApp()

    const isNew = computed<boolean>(
      () => filtersStore.active.condition === ECondition.New
    )

    const isUsed = computed<boolean>(() => !isNew.value)

    const isRetailerMode = computed<boolean>(() => {
      return filtersStore.isRetailerMode
    })

    const resultsRoute = computed<ResultsRoute>(() => {
      return {
        name: ERoutes.Results,
        params: {
          condition: filtersStore.routeCondition,
          type: customerTypeStore.getCustomerTypeRouteParam
        },
        query: query.value as LocationQueryRaw
      }
    })

    const query = computed<IBaseCriteria>(() => {
      const SortId = filtersStore.sorting

      return {
        ...filterCollection.getQueryParams(filtersStore.active, {
          New: filterDataStore.getFilterDataNew,
          Used: filterDataStore.getFilterDataUsed
        }).asObject,
        ...(SortId ? { SortId } : {})
      }
    })

    const analytics = (action: string, category?: 'new' | 'used') => {
      const eventCategory =
        category === 'new'
          ? 'global_header_find_new_cars'
          : category === 'used'
            ? 'global_header_find_approved_used'
            : 'global header'

      $dataLayer.linkClick({
        category: eventCategory,
        action: action ? action.toLowerCase() : ''
      })
    }

    // Mega menu
    const condition = ref<'new' | 'used'>()

    const [isOpen, toggle] = useToggle(false)

    const isActive = (value: 'new' | 'used') => {
      if (!isOpen.value) return false

      return condition.value === value
    }

    const closeTimeout = ref<NodeJS.Timeout | null>(null)

    const open = (value?: 'new' | 'used') => {
      if (value) {
        condition.value = value

        $dataLayer.eecImpression({
          category: 'global header',
          action:
            condition.value === 'new'
              ? 'find new cars opened'
              : 'find approved used opened'
        })
      }

      // Stop closing the mega menu
      if (closeTimeout.value) {
        clearTimeout(closeTimeout.value)
        closeTimeout.value = null
      }

      if (!isOpen.value) {
        toggle()
      }
    }

    // Add delay to close to avoid accidental close
    const close = () => {
      closeTimeout.value = setTimeout(() => {
        if (isOpen.value) toggle()

        closeTimeout.value = null
      }, 250)
    }

    // Close on escape
    onKeyStroke('Escape', (e) => {
      e.preventDefault()
      close()
    })

    // Value My Car
    const { isEnabled: valueMyCarEnabled } = useFeatureFlag(
      'value_my_car_enabled'
    )

    const formsStore = useFormsStore()
    const { showSidepanel: showFormsSidepanel } = useSidepanel(ESidepanel.FORMS)

    const showVMC = () => {
      showFormsSidepanel()

      formsStore.updateForm(EFormType.ValueMyCar)
    }

    return {
      isNew,
      isUsed,
      isRetailerMode,
      resultsRoute,
      analytics,
      condition,
      isOpen,
      isActive,
      open,
      close,
      showVMC,
      valueMyCarEnabled
    }
  }
})
</script>

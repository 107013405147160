<template>
  <nav class="bg-gray-950 text-white">
    <Transition
      mode="out-in"
      enter-active-class="transition-all duration-200 ease-out"
      leave-active-class="transition-all duration-200 ease-in"
      :enter-from-class="
        activeMenu ? 'opacity-0 translate-x-5' : 'opacity-0 -translate-x-5'
      "
      :leave-to-class="
        activeMenu ? 'opacity-0 -translate-x-5' : 'opacity-0 translate-x-5'
      "
    >
      <div v-if="!activeMenu">
        <ul class="space-y-5 -mx-4">
          <li v-if="!isRetailerMode || isNew">
            <button
              class="w-full text-left px-4 py-2 rounded hover:bg-gray-800 focus:outline-none focus:bg-gray-800 flex justify-between items-center"
              @click="toggleSubMenu('new'), analytics('new opened')"
            >
              <span>Find New Cars</span>

              <OsIcon
                name="Arrow"
                class="w-4 -rotate-90"
                :class="activeMenu === 'new' ? 'transform rotate-0' : ''"
              />
            </button>
          </li>
          <li v-if="!isRetailerMode || isUsed">
            <button
              class="w-full text-left px-4 py-2 rounded hover:bg-gray-800 focus:outline-none focus:bg-gray-800 flex justify-between items-center"
              @click="toggleSubMenu('used'), analytics('approved used opened')"
            >
              <span>Approved Used Cars</span>
              <OsIcon
                name="Arrow"
                class="w-4 -rotate-90"
                :class="activeMenu === 'used' ? 'transform rotate-0' : ''"
              />
            </button>
          </li>
          <li v-if="valueMyCarEnabled">
            <button
              class="w-full text-left px-4 py-2 rounded hover:bg-gray-800 flex justify-between items-center"
              @click="analytics('value my car'), showVMC(), close()"
            >
              <span>Value My Car</span>
              <OsIcon name="Arrow" class="w-4 -rotate-90" />
            </button>
          </li>
          <li>
            <NuxtLink
              to="/page/faq"
              class="w-full text-left px-4 py-2 rounded hover:bg-gray-800 flex justify-between items-center"
              @click="analytics('FAQ'), close()"
            >
              <span>Frequently Asked Questions</span>
              <OsIcon name="Arrow" class="w-4 -rotate-90" />
            </NuxtLink>
          </li>
        </ul>

        <HeaderCustomerTypeToggle class="mt-10" />
      </div>

      <div v-else>
        <div v-if="activeMenu === 'new'">
          <p class="text-gray-400 font-thin mb-5">Find New cars</p>
          <ul class="space-y-2 -mx-4 mb-9">
            <li>
              <NuxtLink
                to="/new/model/eq-range"
                class="block px-4 py-2 rounded hover:bg-gray-800"
                @click="analytics('find: Electric cars', 'new'), close()"
              >
                Electric cars
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                to="/new/model/suv-range"
                class="block px-4 py-2 rounded hover:bg-gray-800"
                @click="analytics('find: The SUV Range', 'new'), close()"
              >
                The SUV range
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                to="/new/models"
                class="block px-4 py-2 rounded hover:bg-gray-800"
                @click="analytics('find: See the full range', 'new'), close()"
              >
                See the full range
              </NuxtLink>
            </li>
          </ul>

          <p class="text-gray-400 font-thin mb-5">We recommend</p>
          <ul class="space-y-2 -mx-4 mb-9">
            <li>
              <NuxtLink
                to="/new/model/cle"
                class="block px-4 py-2 rounded hover:bg-gray-800"
                @click="
                  analytics('recommend: The all new CLE range', 'new'), close()
                "
              >
                The all new CLE range
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                to="/new/model/phev-range"
                class="block px-4 py-2 rounded hover:bg-gray-800"
                @click="analytics('recommend: The PHEV range', 'new'), close()"
              >
                The PHEV range
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                to="/new/model/a-class"
                class="block px-4 py-2 rounded hover:bg-gray-800"
                @click="
                  analytics('recommend: The A-Class range', 'new'), close()
                "
              >
                The A-Class range
              </NuxtLink>
            </li>
          </ul>

          <p class="text-gray-400 font-thin mb-5">Icons</p>
          <ul class="space-y-2 -mx-4 mb-9">
            <li>
              <NuxtLink
                to="/new/model/amg-range"
                class="block px-4 py-2 rounded hover:bg-gray-800"
                @click="analytics('icons: Mercedes-AMG', 'new'), close()"
              >
                Mercedes-AMG
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                to="/new/model/g-class"
                class="block px-4 py-2 rounded hover:bg-gray-800"
                @click="analytics('icons: G-Class', 'new'), close()"
              >
                G-Class
              </NuxtLink>
            </li>
          </ul>
        </div>

        <div v-if="activeMenu === 'used'">
          <p class="text-gray-400 font-thin mb-5">Find Approved Used cars</p>
          <ul class="space-y-2 -mx-4 mb-9">
            <li>
              <NuxtLink
                to="/used/model/eq-range"
                class="block px-4 py-2 rounded hover:bg-gray-800"
                @click="analytics('find: Electric Cars', 'used'), close()"
              >
                Electric cars
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                to="/used/model/a-class"
                class="block px-4 py-2 rounded hover:bg-gray-800"
                @click="analytics('find: The A-Class Range', 'used'), close()"
              >
                The A-Class range
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                to="/used/models"
                class="block px-4 py-2 rounded hover:bg-gray-800"
                @click="
                  analytics('find: Browse all used cars', 'used'), close()
                "
              >
                See the full range
              </NuxtLink>
            </li>
          </ul>

          <p class="text-gray-400 font-thin mb-5">We recommend</p>
          <ul class="space-y-2 -mx-4 mb-9">
            <li>
              <NuxtLink
                to="/used/model/amg-range"
                class="block px-4 py-2 rounded hover:bg-gray-800"
                @click="analytics('recommend: Mercedes-AMG', 'used'), close()"
              >
                Mercedes-AMG
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                to="/used/model/phev-range"
                class="block px-4 py-2 rounded hover:bg-gray-800"
                @click="analytics('recommend: The PHEV range', 'used'), close()"
              >
                The PHEV range
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                to="/used/model/suv-range"
                class="block px-4 py-2 rounded hover:bg-gray-800"
                @click="analytics('recommend: The SUV range', 'used'), close()"
              >
                The SUV range
              </NuxtLink>
            </li>
          </ul>
        </div>

        <HeaderValueMyCar class="mt-10" :condition="activeMenu" />
      </div>
    </Transition>
  </nav>
</template>

<script lang="ts" setup>
import { useFiltersStore } from '~/stores/filtersStore'
import { useFormsStore } from '~/stores/forms/formsStore'
import { ECondition } from '~/types/vehicle'
import { EFormType } from '~/types/forms'

const props = defineProps<{
  modelValue: string | null
  isOpen: boolean
}>()

const emit = defineEmits(['update:modelValue', 'close'])

const close = () => emit('close')

const activeMenu = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
})

const toggleSubMenu = (menu: string) => {
  activeMenu.value = activeMenu.value === menu ? null : menu
}

watch(
  () => props.isOpen,
  (value) => {
    if (!value) {
      activeMenu.value = null
    }
  }
)

const { $dataLayer } = useNuxtApp()

const analytics = (action: string, category?: 'new' | 'used') => {
  const eventCategory =
    category === 'new'
      ? 'global_header_find_new_cars'
      : category === 'used'
        ? 'global_header_find_approved_used'
        : 'global header'

  $dataLayer.linkClick({
    category: eventCategory,
    action: action ? action.toLowerCase() : ''
  })
}

const filtersStore = useFiltersStore()

const isNew = computed<boolean>(
  () => filtersStore.active.condition === ECondition.New
)

const isUsed = computed<boolean>(() => !isNew.value)

const isRetailerMode = computed<boolean>(() => {
  return filtersStore.isRetailerMode
})

// Value My Car
const { isEnabled: valueMyCarEnabled } = useFeatureFlag('value_my_car_enabled')

const formsStore = useFormsStore()
const { showSidepanel: showFormsSidepanel } = useSidepanel(ESidepanel.FORMS)

const showVMC = () => {
  showFormsSidepanel()

  formsStore.updateForm(EFormType.ValueMyCar)
}
</script>

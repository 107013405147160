<template>
  <div class="calendar os-form__section">
    <p class="os-form__subtitle">
      Choose your preferred date and time for your Test Drive
    </p>
    <p class="os-form__disclaimer mb-6">
      Your Showroom will be in contact with you to organise your experience. If
      your preferred date and time isn’t available, then we will find another
      time that works.
    </p>

    <TabItems>
      <TabButton
        :is-selected="!isAfternoon"
        title="Morning"
        @select-tab="isAfternoon = false"
      >
        Morning
      </TabButton>
      <TabButton
        :is-selected="isAfternoon"
        title="Afternoon"
        @select-tab="isAfternoon = true"
      >
        Afternoon
      </TabButton>
    </TabItems>

    <OsDatePicker
      v-model.date="selectedDate"
      class="calendar__datePicker"
      :min-date="minDate"
      expanded
    />

    <OsButtonTray>
      <OsButton
        :is-loading="isLoading"
        :is-primary="true"
        v-bind="{ isDisabled }"
        @click.prevent="$emit('next')"
      >
        {{ isLoading ? 'Please wait' : `Next: ${nextStepTitle}` }}
      </OsButton>
    </OsButtonTray>
  </div>
</template>

<script lang="ts">
import { useTestDriveStore } from '~/stores/forms/testDriveStore'
import { NewVehicle, UsedVehicle } from '~/types/vehicle'

export default {
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      defualt: false
    },
    vehicle: {
      type: Object as PropType<NewVehicle | UsedVehicle>,
      required: false,
      default: () => ({})
    },
    nextStepTitle: {
      type: String,
      required: false,
      default: ''
    }
  },

  emits: ['next'],

  setup(props) {
    const testDriveStore = useTestDriveStore()
    const isAfternoon = computed<boolean>({
      get() {
        return testDriveStore.isAfternoon
      },
      set(val) {
        testDriveStore.isAfternoon = val
      }
    })

    const selectedDate = computed<Date | null>({
      get() {
        return testDriveStore.date as Date | null
      },
      set(val) {
        testDriveStore.date = val
      }
    })

    const minDate = computed(() => {
      const date = new Date()
      const startTestDrive = new Date('2021-04-15')
      const newdate = new Date(date)
      return newdate.setDate(
        newdate > startTestDrive ? newdate.getDate() + 3 : 19
      )
    })

    const isDisabled = computed<boolean>(
      () => props.isLoading || !selectedDate.value
    )

    return {
      isAfternoon,
      selectedDate,
      minDate,
      isDisabled
    }
  }
}
</script>

<style scoped lang="scss">
.calendar__datePicker {
  overflow: auto;
}
</style>

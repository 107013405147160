<template>
  <header
    id="os-header"
    class="bg-gray-950 text-white flex items-center h-14 px-6 z-30 top-0"
    :class="{
      'site-header--hidden': hideHeader,
      'relative md:sticky': isVehiclePage || isResultsPage
    }"
  >
    <HeaderLogo />

    <div class="container mx-auto max-w-7xl flex h-full">
      <HeaderNavigation />

      <div class="grow" />

      <HeaderCustomerTypeToggle class="hidden lg:flex" />

      <HeaderAccountNavigation />

      <div class="self-center ml-6 lg:hidden">
        <button
          class="flex"
          aria-label="Menu"
          @click.prevent="toggleSidepanel()"
        >
          <OsIcon name="Menu" />
        </button>
      </div>
    </div>

    <OsSlideover v-model="isSidepanelOpen">
      <div class="flex items-center justify-between mb-10">
        <transition
          mode="out-in"
          enter-active-class="transition duration-100 ease-out"
          leave-active-class="transition duration-100 ease-in"
          :enter-from-class="
            activeMenu ? 'opacity-0 -translate-x-5' : 'opacity-0 translate-x-5'
          "
          :leave-to-class="
            activeMenu ? 'opacity-0 translate-x-5' : 'opacity-0 -translate-x-5'
          "
        >
          <HeaderLogo v-if="!activeMenu" />
          <button
            v-else
            aria-label="back"
            class="flex items-center gap-2 h-8"
            @click="goBack()"
          >
            <OsIcon name="Arrow" class="text-white rotate-90" />
            <span>Back</span>
          </button>
        </transition>

        <aside class="flex items-center gap-6">
          <HeaderAccountNavigation />

          <OsIconButton
            aria-label="close"
            color="gray"
            @click.prevent="toggleSidepanel()"
          >
            <OsIcon name="Close" class="text-white" />
          </OsIconButton>
        </aside>
      </div>

      <HeaderVerticalNavigation
        v-model="activeMenu"
        :is-open="isSidepanelOpen"
        @close="toggleSidepanel()"
      />
    </OsSlideover>
  </header>
</template>

<script lang="ts">
import { useSettingsStore } from '~/stores/settingsStore'
import { EButtonThemes } from '~/components/base/Button.vue'
import { ERoutes } from '~/types/routes'

export default {
  setup() {
    const { $dataLayer, $bus } = useNuxtApp()
    const route = useRoute()

    const settingsStore = useSettingsStore()

    const isHomepage = computed(
      () => route.path === '/new' || route.path === '/used'
    )

    const isVehiclePage = computed(() => route.name === ERoutes.Vehicle)
    const isResultsPage = computed(() => route.name === ERoutes.Results)

    const { onLogin } = useCiamLogin()

    const login = () => {
      onLogin()

      $dataLayer.linkClick({
        category: 'global header',
        action: 'login'
      })
    }

    const { isViewportDesktop } = useUi()

    const hideHeader = computed(() => {
      if (isViewportDesktop.value) return false

      return settingsStore.modes.finance
    })

    // Slideover
    const [isSidepanelOpen, toggleSidepanel] = useToggle()

    onMounted(() =>
      $bus.on('mega-menu:close', () => {
        isSidepanelOpen.value = false
      })
    )

    onBeforeUnmount(() => $bus.off('mega-menu:close'))

    // Vertical Navigation
    const activeMenu = ref<string | null>(null)

    const goBack = () => {
      activeMenu.value = null

      $dataLayer.linkClick({
        category:
          activeMenu.value === 'new'
            ? 'global_header_find_new_cars'
            : 'global_header_find_approved_used_cars',
        action: 'back'
      })
    }

    return {
      ESidepanel,
      isViewportDesktop,
      isHomepage,
      isVehiclePage,
      isResultsPage,
      login,
      EButtonThemes,
      hideHeader,
      isSidepanelOpen,
      toggleSidepanel,
      activeMenu,
      goBack
    }
  }
}
</script>

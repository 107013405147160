<template>
  <div>
    <div class="os-form__section">
      <p class="os-form__text-content">
        This valuation is indicative of what your car may be worth and is based
        on the information you’ve input. The final price for your car will be
        agreed with your Showroom.
      </p>

      <div class="os-form__subtitle">Your valuation</div>
      <AccordionMenu single-item borderless :bold="false" class="mb-6">
        <AccordionItem>
          <AccordionItemTitle>Your valuation details</AccordionItemTitle>
          <AccordionItemContent>
            <div
              v-if="currentModel"
              data-locator="current-model"
              class="os-form__section pt-4"
            >
              <div class="os-form__subtitle">Your car</div>
              <div class="os-form__section">
                <TagCard
                  :title="currentModel.heading"
                  :tags="currentModel.tags"
                />
              </div>
            </div>

            <div class="os-form__section">
              <div class="os-form__subtitle">Next steps</div>
              <ul class="os-form__list os-form__section">
                <li v-for="step in nextSteps" :key="step">{{ step }}</li>
              </ul>
            </div>
          </AccordionItemContent>
        </AccordionItem>
      </AccordionMenu>

      <div class="os-form__section">
        <div
          v-if="valueMyCarStore.indicativeValuation !== null"
          class="os-form__card-bar"
        >
          <p>Your indicative car valuation</p>
          <h6 data-locator="indicative-value">
            {{ formatThousands(valueMyCarStore.indicativeValuation) }}
          </h6>
        </div>
        <p class="os-form__text-content mb-0">
          <a
            class="os-form__link"
            href="/page/value-my-car-terms"
            target="_blank"
            >Terms and Conditions</a
          >
          <span> apply.</span>
        </p>
      </div>

      <div
        v-if="
          valueMyCarStore.isSettlementPresent &&
          valueMyCarStore.settlementFigure !== null
        "
        data-locator="settlement"
        class="os-form__section"
      >
        <div class="os-form__subtitle">Your Settlement Figure</div>
        <div class="os-form__text-content">
          <div class="os-form__card-bar">
            <p>Your stated Settlement Figure</p>
            <h6 data-locator="settlement-value">
              {{ formatThousands(valueMyCarStore.settlementFigure) }}
            </h6>
          </div>
        </div>
      </div>
    </div>

    <FormsSectionMarketingDeclaration v-if="isEnabled" />

    <OsButtonTray>
      <OsButton @click.prevent="$emit('valueOtherCar')">
        Value another car
      </OsButton>
      <OsButton :is-primary="true" @click.prevent="addToFinance">
        Add to Finance
      </OsButton>
    </OsButtonTray>
  </div>
</template>

<script lang="ts">
import { useFinanceCriteriaStore } from '~/stores/finance/criteriaStore'
import {
  useValueMyCarStore,
  valuationNextSteps
} from '~/stores/forms/valueMyCarStore'

export default {
  name: 'Valuation',

  emits: ['valueOtherCar', 'addToFinance'],

  setup(_, { emit }) {
    const valueMyCarStore = useValueMyCarStore()
    const financeCriteriaStore = useFinanceCriteriaStore()

    const currentModel = computed(() => valueMyCarStore.currentModel)

    const addToFinance = () => {
      financeCriteriaStore.partExchange =
        valueMyCarStore.indicativeValuation || 0

      emit('addToFinance')
    }

    const formatThousands = (val: string): string => {
      return `£${formatWithCommasAndDecimals(val)}`
    }

    const { isEnabled } = useFeatureFlag('onedoc_enabled')

    return {
      valueMyCarStore,
      currentModel,
      nextSteps: valuationNextSteps,
      addToFinance,
      formatThousands,
      isEnabled
    }
  }
}
</script>

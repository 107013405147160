<template>
  <div>
    <InputText
      :model-value="fieldValue"
      :name="field.Identifier"
      :validate="isValidateField(field)"
      :is-error-state="!!fieldErrors.length"
      :placeholder="fieldLabel"
      :rules="isRequired ? 'required' : ''"
      :type="fieldType"
      :pattern="fieldPattern"
      @input="
        ($event) => {
          validateInput($event, field)
        }
      "
    />
    <OsError v-if="fieldErrors.length">
      {{ fieldErrors[0] }}
    </OsError>
  </div>
</template>

<script lang="ts">
import { IOcdField } from '~/types/forms'

// https://github.com/vuejs/vue/issues/7136
// we need a number input so it doesn't remove the initial "0" (e.g. 0002 -> 2) as this is needed it in the bank account field
// todo -> remove if they change this field to type: string
export default {
  name: 'OcdFormFactoryNumberInput',
  props: {
    field: {
      type: Object as PropType<IOcdField>,
      required: true
    }
  },
  setup(props) {
    const { updateFormValue, getFieldValue, isValidateField, getFieldErrors } =
      useFormFactory()

    const fieldValue = computed(() => getFieldValue(props.field))
    const isDataSaved = computed(() => props.field.Value.length > 0)
    const fieldErrors = computed(() => getFieldErrors(props.field))
    const fieldLabel = computed(() => `${props.field.Label}`)
    const fieldPattern = computed(() =>
      props.field.Key === 'AccountNumber' ? '[-+]?[0-9]*[.,]?[0-9]+' : undefined
    )
    const fieldType = computed(() =>
      props.field.Key === 'AccountNumber'
        ? 'text'
        : props.field.Type && props.field.Type.toLowerCase()
    )

    const isRequired = computed(() => props.field.IsRequired)

    const validateInput = ($event, field) => {
      const inputValue = $event.target.value
      const nonNegativeValue = inputValue < 0 ? 0 : inputValue
      updateFormValue({ field, value: nonNegativeValue })
    }

    return {
      fieldValue,
      isDataSaved,
      fieldErrors,
      fieldLabel,
      fieldType,
      fieldPattern,
      isValidateField,
      updateFormValue,
      validateInput,
      isRequired
    }
  }
}
</script>

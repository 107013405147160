<template>
  <section class="os-form__section">
    <p class="os-form__text-content">
      Your selected Showroom will be in touch to discuss next steps regarding
      your indicative valuation once you’ve submitted the form.
    </p>
    <Form as="section">
      <InputRetailer v-model="retailer" :include-local="false" is-trade-in />

      <OsButtonTray>
        <OsButton
          is-primary
          :is-disabled="!retailer"
          @click.prevent="$emit('next')"
        >
          {{ `Next: ${nextStepTitle}` }}
        </OsButton>
      </OsButtonTray>
    </Form>
  </section>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia'
import { useValueMyCarStore } from '~/stores/forms/valueMyCarStore'

export default {
  name: 'Retailer',
  props: {
    nextStepTitle: {
      type: String,
      required: true,
      default: ''
    }
  },

  emits: ['next'],

  setup() {
    const valueMyCarStore = useValueMyCarStore()

    const { retailer } = storeToRefs(valueMyCarStore)

    return {
      retailer
    }
  }
}
</script>

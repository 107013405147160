<template>
  <div class="flex items-center">
    <OsToggle v-model="isBusinessMode" />
    <span class="text-lg ml-2">{{ EDropdownCustomerTypes.Business }}</span>
  </div>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia'
import { EUserType } from '~/types/googleDataLayer'
import { ERouteConditions, ERoutes } from '~/types/routes'
import { useFiltersStore } from '~/stores/filtersStore'
import { useCustomerTypeStore } from '~/stores/customerTypeStore'
import { ECondition } from '~/types/vehicle'

enum EDropdownCustomerTypes {
  Private = 'Private Individual',
  Business = 'Business',
  Motability = 'Motability'
}

export default {
  setup() {
    const router = useRouter()
    const route = useRoute()
    const { $dataLayer } = useNuxtApp()
    const filtersStore = useFiltersStore()
    const customerTypeStore = useCustomerTypeStore()

    const { active } = storeToRefs(filtersStore)

    const { updateCondition } = useFilters(active)

    const isBusinessMode = computed<boolean>({
      get() {
        return !!customerTypeStore.isBusiness
      },
      set(val) {
        const isUsed = active.value.condition === ECondition.Used

        // Business is only available for new.
        updateCondition(ECondition.New)

        if (val) {
          customerTypeStore.enableBusiness()
          analytics(EUserType.Business)
        } else {
          customerTypeStore.enablePrivate()
          analytics(EUserType.Private)
        }

        resetAndRedirect(isUsed)
      }
    })

    const retailerModeQuery = computed(() => {
      const { RetailerGroup, Retailer } = route.query
      const query: any = {}

      if (RetailerGroup) query.RetailerGroup = RetailerGroup
      if (Retailer) query.Retailer = Retailer

      return query
    })

    const resetAndRedirect = async (isUsed: boolean) => {
      const isDetailsPage = route.name === ERoutes.Vehicle

      await router
        .push({
          name: isUsed && isDetailsPage ? ERoutes.Homepage : route.name,
          params: {
            condition: ERouteConditions.New,
            type: customerTypeStore.getCustomerTypeRouteParam
          },
          query: isUsed && isDetailsPage ? retailerModeQuery.value : route.query
        } as any)
        .catch(() => {})

      if (isUsed) {
        filtersStore.updateSorting()
      }
    }

    const analytics = (userType: EUserType) => {
      $dataLayer.linkClick({
        category: 'global header',
        action: userType
      })
    }

    return {
      isBusinessMode,
      EDropdownCustomerTypes
    }
  }
}
</script>
